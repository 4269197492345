let authChecked = false;

export default async ({ app, store, redirect }) => {
    if (store.getters.isLoggedIn) return redirect(store.getters.getHomeURL);

    if (!authChecked) {
        // 初回の着地時のみチェック
        authChecked = true;
        try {
            await store.dispatch('authenticate', { app });
        } catch {
            return;
        }

        redirect(store.getters.getHomeURL);
    }
};
