// @ts-check

/**
 * Vuex/mutations用。
 * 加工なしにシンプルに値を設定したい時に使う。
 * @param {String} property 対象となるstateの名称
 * @param {*} [defaultVal] 上書きする値を指定しなかったときに設定する値
 * @return {Function}
 */
export const set = (property, defaultVal) => (state, payload) => {
    state[property] = payload ?? defaultVal;
};

/**
 * Vuex/getters用。
 * stateの値をそのまま返したい時に使う。
 * @param {String} property 対象となるstateの名称
 * @return {Function}
 */
export const get = (property) => (state) => state[property];
