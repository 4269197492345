<template>
    <div ref="container" class="container" :class="{ collapsed: isCollapsed }">
        <div data-testid="side-menu" :style="`top: ${top}px;`" class="menu">
            <ul class="primary-links">
                <MenuItem v-for="item in primaryLinks" :key="item.id" :item="item" :isCollapsed="isCollapsed" />
            </ul>

            <ul class="secondary-links">
                <MenuItem v-for="item in secondaryLinks" :key="item.id" :item="item" :isCollapsed="isCollapsed" />
            </ul>

            <el-button class="collapse-btn" circle icon="el-icon-d-arrow-left" @click="toggleCollapse" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import portfolioIcon from '@/assets/images/design/icon_portfolio-white.svg';
import importIcon from '@/assets/images/design/icon_import-white.svg';
import pendingIcon from '@/assets/images/design/pending-trades.svg';
import tradesIcon from '@/assets/images/design/tradehistory.svg';
import inspectionIcon from '@/assets/images/design/errorcheck.svg';
import downloadIcon from '@/assets/images/design/downloadjournal.svg';
import settingsIcon from '@/assets/images/design/settings.svg';
import helpIcon from '@/assets/images/icon_help.svg';
import bellIcon from '@/assets/images/icon_bell.svg';
import reviewIcon from '@/assets/images/design/friendly_balloon.svg';
import MenuItem from './SideMenu/MenuItem.vue';

export default {
    components: {
        MenuItem,
    },
    data() {
        return {
            top: 0,
            isCollapsed: window.innerWidth < 1200,
        };
    },
    computed: {
        ...mapGetters([
            'getIsRegularUser',
            'getIsCreator',
            'getIsDapps',
            'serviceName',
            'getCurrentUser',
            'getUnimportedTradeCount',
            'getPendingTradeCount',
            'getPendingDefiCount',
            'getAccountingTransferCount',
            'getTxConfirmationCount',
            'isUsingFreee',
            'hasUnreadNews',
            'isRestrictedUser',
        ]),
        hasPendingAlert() {
            return (
                this.getPendingTradeCount.total > 0 ||
                this.getPendingDefiCount.total > 0 ||
                this.getAccountingTransferCount > 0 ||
                this.getTxConfirmationCount > 0
            );
        },
        primaryLinkList() {
            return [
                {
                    id: '/portfolio',
                    label: 'ポートフォリオ',
                    icon: portfolioIcon,
                },
                {
                    id: '/import',
                    label: 'データ取込',
                    icon: importIcon,
                    alert: this.getUnimportedTradeCount.total > 0,
                    isRestricted: this.isRestrictedUser,
                    sub: this.getCurrentUser.feature_toggle?.api_key_register
                        ? [
                              { id: '/import', label: 'データ取込' },
                              { id: '/import/api', label: 'API連携 - β版' },
                          ]
                        : undefined,
                },
                {
                    id: '/pending-trades',
                    label: '要処理',
                    icon: pendingIcon,
                    alert: this.hasPendingAlert,
                    isRestricted: this.isRestrictedUser,
                },
                {
                    id: '/trades',
                    label: '取引一覧',
                    icon: tradesIcon,
                    isRestricted: this.isRestrictedUser,
                },
                {
                    id: '/inspectation',
                    label: 'エラー検証',
                    icon: inspectionIcon,
                    isRestricted: this.isRestrictedUser,
                },
                {
                    id: '/journal',
                    label: '仕訳',
                    icon: downloadIcon,
                    hidden: this.getIsRegularUser,
                },
                // {
                //     id: '/accounting-books',
                //     label: '会計帳簿',
                //     icon: tradesIcon, // TODO: 仮のアイコンです　指定お願いします
                //     hidden: !this.getIsDapps,
                //     sub: [{ id: '/accounting-books/journal-list', label: '仕訳帳 - β版' }],
                // },
                {
                    id: '/journal-master',
                    label: '設定',
                    icon: settingsIcon,
                    hidden: !this.getIsDapps,
                    sub: [
                        { id: '/journal-master/addresses', label: 'アドレスマスタ' },
                        { id: '/journal-master/accounts', label: '勘定科目設定' },
                        { id: '/journal-master/clients', label: '取引先設定' },
                        { id: '/journal-master/departments', label: '部門設定' },
                        { id: '/journal-master/tax-classes', label: '税区分設定' },
                        { id: '/journal-master/items', label: '品目設定', hidden: !this.isUsingFreee },
                        { id: '/journal-master/rules', label: '取引ルール設定' },
                        { id: '/journal-master/valuation-rules', label: '時価評価仕訳ルール設定' },
                    ],
                },
            ];
        },
        primaryLinks() {
            return this.primaryLinkList
                .filter(({ hidden }) => !hidden)
                .map((l) => ({
                    ...l,
                    sub: l.sub?.filter(({ hidden }) => !hidden),
                }));
        },
        secondaryLinks() {
            const list = [
                {
                    id: '/news',
                    label: 'お知らせ',
                    icon: bellIcon,
                    alert: this.hasUnreadNews,
                },
            ];

            // 個人版はzendeskのウィジェットがガイドになるためメニューには入れない
            if (!this.getIsRegularUser)
                list.push({
                    id: this.guideLink,
                    label: `${this.serviceName}の使い方`,
                    icon: helpIcon,
                });
            if (this.getIsRegularUser)
                list.push({
                    id: 'https://forms.gle/iQQLw6J4JtUxnPfr8',
                    label: '意見箱',
                    icon: reviewIcon,
                });

            return list;
        },
        guideLink() {
            return this.getIsCreator
                ? 'https://ruddy-delivery-23e.notion.site/Gtax-for-Creators-4d356af82d104090b75f8e05c0c18da5'
                : 'https://support.crypto-city.net/hc/ja/';
        },
    },
    mounted() {
        // fixedになっている子エレメントのトップをコンテナに合わせる
        this.top = this.$refs.container.getClientRects()[0].top;
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    --duration: 0.2s;
    --collapsed-width: 55px;
    position: relative;
    width: 180px;
    height: 100%;
    transition-property: width;
    transition-duration: var(--duration);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &.collapsed {
        width: var(--collapsed-width);

        .menu {
            width: var(--collapsed-width);
        }
        .collapse-btn {
            transform: translateX(4px) rotate(180deg);
        }
    }
}
.menu {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    width: 180px;
    margin-top: 24px;
    margin-bottom: 20px;
    padding-top: 12px;
    padding-bottom: 64px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 1.4rem;
    font-weight: bold;
    background-image: linear-gradient(to right, #37b6cb, #5473e4);

    box-shadow: 0px 0px 12px rgba(0, 65, 114, 0.4);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition-property: width;
    transition-duration: var(--duration, 0.2s);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.primary-links {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-auto-rows: 56px;
}
.secondary-links {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-auto-rows: 46px;
}
.collapse-btn {
    position: absolute;
    right: 13px;
    bottom: 14px;
    background-color: #1e539f;
    border: none;
    padding: 14px;

    ::v-deep i {
        color: #fff;
        transition: transform var(--duration, 0.2s);
    }
    &:hover ::v-deep i {
        transform: translateX(-2px);
    }
}
</style>

<style>
/* サブメニュー用のtransition。各サブメニューのコンポーネントで定義すると無駄が多いためここで定義 */
.sub-enter-active,
.sub-leave-active {
    transition: var(--duration);
}
.sub-enter,
.sub-leave-to {
    opacity: 0;
    transform: translateX(-6px);
}
</style>
