// @ts-check
const restrictedPaths = ['/trades-setting', '/beginning-balance'];
const restrictedDirs = ['/import', '/pending-trades', '/trades', '/inspectation'];

/**
 * 与えたパスが、利用制限がかかっているユーザーにとって制限されたパスかどうかを返す
 * @param {string} path
 * @returns {boolean}
 */
export const isRestrictedPath = (path) => {
    return restrictedPaths.includes(path) || restrictedDirs.some((dir) => path.startsWith(dir));
};
