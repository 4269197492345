import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const sentryPlugin =
    ({ replay }) =>
    ({ isDev, app, $config }) => {
        if (isDev || $config.appEnv === 'testing') return;

        const options = {
            Vue,
            dsn: 'https://310d60dbb3564aaea50d5964093d808c@o495857.ingest.sentry.io/6548872',
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(app.router),
                }),
            ],
            environment: $config.appEnv,
            sampleRate: 1,
            tracesSampleRate: 0.1,
        };

        if (replay === true) {
            options.integrations.push(new Sentry.Replay());
            options.replaysOnErrorSampleRate = 1;
            options.replaysSessionSampleRate = 0.1;
        }

        Sentry.init(options);
    };

/**
 * 大きなリリースの後など、SentryのReplay機能を有効にしたい場合は{ replay: true }を渡す。
 */
export default sentryPlugin({ replay: false });
