export const SOFTWARE = {
    kanjo: 'kanjobugyo',
    freee: 'freee',
    // kanjoCloud: 'kanjobugyo_cloud',
    // yayoi: 'yayoi',
    // moneyForward: 'money_forward',
};

export const accountingSoftwares = new Map([
    [SOFTWARE.kanjo, '勘定奉行'],
    [SOFTWARE.freee, 'freee'],
    // ↓今後実装予定
    // [SOFTWARE.kanjoCloud, '勘定奉行（クラウド）'],
    // [SOFTWARE.yayoi, '弥生会計'],
    // [SOFTWARE.moneyForward, 'MonerForward'],
]);
