// @ts-check

import { hasValue } from '@/libs/validators/validationFunctions.js';
import { isValidNumber, flattenNumStr, isNumeric } from './base.js';

/**
 * formatNumberIntlの簡易版。特に変わったオプションが必要ない場合はこちらの使用を推奨。
 * 詳しい使い方はtestを参照。(test/libs/utils/number.test.js)
 * @param {string|number} numOrStr
 * @param {Intl.NumberFormatOptions['minimumFractionDigits']} [minimumFractionDigits] 最低小数桁数
 * @param {Intl.NumberFormatOptions['maximumFractionDigits']} [maximumFractionDigits] 最大小数桁数
 * @returns {string}
 */
export const formatNumber = (numOrStr, minimumFractionDigits, maximumFractionDigits) => {
    return formatNumberIntl(numOrStr, { minimumFractionDigits, maximumFractionDigits });
};

/**
 * formatNumber(value, 0, 0)のエイリアス。整数部分のみ返す。
 * @param {string|number} numOrStr
 * @returns
 */
export const formatInt = (numOrStr) => {
    return formatNumberIntl(numOrStr, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

/**
 * @param {string|number} numOrStr
 * @param {Intl.NumberFormatOptions} options
 * @returns {string}
 */
export const formatNumberIntl = (numOrStr, options = {}) => {
    if (!hasValue(numOrStr)) return undefined;

    // カンマ除去と指数表記の展開
    const input = flattenNumStr(numOrStr);

    if (!isValidNumber(Number(input))) return undefined;
    const isMinus = Number(input) < 0;

    const [integer, fraction = ''] = input.split('.');

    // 整数部分のみのフォーマットなので、小数部分の桁数を0に強制する
    /** @type {Intl.NumberFormatOptions} */
    const enforcedIntOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };

    // Numberで扱えない桁数の場合、stringで渡すと丸め処理が入ってしまうので、BigIntで渡す
    const formatter = new Intl.NumberFormat('ja-JP', { ...options, ...enforcedIntOptions });
    let formattedInt = '';
    try {
        formattedInt = formatter.format(BigInt(integer));
    } catch (_) {
        // formatterがBigInt非対応のブラウザでは、Numberで処理する。・・そういったブラウザは対応外だが一応・・
        formattedInt = formatter.format(Number(integer));
    }

    // 小数が不要な場合はこの時点で整数を返す
    const maxSpecifiedAsZero = options.maximumFractionDigits === 0;
    const noNeedToAddFraction = !fraction && !options.minimumFractionDigits;
    if (maxSpecifiedAsZero || noNeedToAddFraction) return formattedInt;

    // 最後に符号をつけるのでここで符号を外す。
    formattedInt = formattedInt.replace('-', '');

    // 後ろに続く0を一旦削除。
    const shortenedFraction = fraction.replace(/\.?0+$/, '');

    // 小数部分のフォーマット。optionで指定されていればそれに従う
    const minFractionDigits = options.minimumFractionDigits ?? 0;
    const maxFractionDigits = options.maximumFractionDigits ?? Math.max(minFractionDigits, 8);
    const formattedFraction = shortenedFraction.padEnd(minFractionDigits, '0').slice(0, maxFractionDigits);

    const formatted = formattedFraction === '' ? formattedInt : `${formattedInt}.${formattedFraction}`;

    return isMinus ? `-${formatted}` : formatted;
};

/**
 *
 * @param {number | string} numOrStr
 * @returns {string}
 */
export const removeTrailingZeros = (numOrStr) => {
    if (!isNumeric(numOrStr)) return undefined;
    return numOrStr.toString()?.replace(/\.?0+$/, '');
};
