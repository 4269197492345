<template>
    <header class="top-header">
        <a v-if="isTopPage" href="#hero" class="logo-area" role="button">
            <img src="@/assets/images/top/awa/awa_logo.png" alt="AWA" />
        </a>
        <nuxt-link v-else to="/awa" class="logo-area" role="button">
            <img src="@/assets/images/top/awa/awa_logo.png" alt="AWA" />
        </nuxt-link>

        <nuxt-link v-show="!isInquiryPage" class="inquiry" :to="inquiryLink">お問い合わせ</nuxt-link>
    </header>
</template>

<script>
export default {
    props: {
        inquiryLink: { type: String, required: true },
    },
    computed: {
        isTopPage() {
            return this.$route.name === 'awa';
        },
        isInquiryPage() {
            // 末尾にスラッシュがあれば削除
            const path = this.$route.path.replace(/\/$/, '');
            return path === this.inquiryLink;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/personal/base.scss';
.top-header {
    position: sticky;
    z-index: 999;
    width: 100%;
    height: var(--header-height);
    top: 0;
    background: rgba(248, 248, 248, 0.96);
    display: flex;
    justify-content: space-between;

    .logo-area {
        height: 100%;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include wide {
            padding: 0 10px;
        }

        @include sp {
            padding: 0 3vw;
        }

        img {
            vertical-align: middle;
            width: max(100px, 7.5vw);

            @include wide {
                max-width: 120px;
            }

            @include sp {
                width: 19vw;
            }
        }
    }

    .inquiry {
        height: 100%;
        width: clamp(10em, 27vw, 240px);
        padding: 0 1.6em;
        display: inline-grid;
        place-items: center;
        color: #fff;
        background: $ACCENT_COLOR;
        transition: background-color 0.4s ease-in-out;

        &:hover {
            background: rgb(253, 88, 113);
            text-decoration: none;
        }
    }
}
</style>
