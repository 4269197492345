// @ts-check
export const newsUrl = 'https://crypto-city.net/media/category/news';

export const exchangerHowToUrls = {
    aidosmarket: 'https://www.aerial-p.com/media/howtodlaidosmarket.html',
    binance:
        'https://www.aerial-p.com/media/binance%E3%81%AE%E5%8F%96%E5%BC%95%E5%B1%A5%E6%AD%B4%E3%81%AE%E5%8F%96%E5%BE%97%E6%96%B9%E6%B3%95.html',
    bitz: 'https://www.aerial-p.com/media/howtodlbit-z.html',
    bitbank: 'https://www.aerial-p.com/media/howtodlbitbank.html',
    bitfinex: 'https://www.aerial-p.com/media/howtodlbitfinex.html',
    bitflyer: 'https://www.aerial-p.com/media/howtodlbitflyer.html',
    bitget: 'https://www.aerial-p.com/media/howtodlbitget.html',
    bitmax: 'https://www.aerial-p.com/media/howtodlbitmax.html',
    bitmex: 'https://www.aerial-p.com/media/howtodlbitmex.html',
    bitpoint: 'https://www.aerial-p.com/media/howtodlbitpoint.html',
    bitrue: 'https://www.aerial-p.com/media/howtodlbitrue.html',
    bitstamp: 'https://www.aerial-p.com/media/howtodlbitstamp.html',
    bittrex: 'https://www.aerial-p.com/media/howtodlbittrex.html',
    blockfi: 'https://www.aerial-p.com/media/howtodlblockfi.html',
    btcbox: 'https://www.aerial-p.com/media/btcbox%E3%81%AE%E5%8F%96%E5%BC%95%E5%B1%A5%E6%AD%B4%E3%81%AE%E5%8F%96%E5%BE%97%E6%96%B9%E6%B3%95.html',
    bybit: 'https://www.aerial-p.com/media/howtodlbybit.html',
    cex: 'https://www.aerial-p.com/media/howtodlcex-io.html',
    changelly: 'https://www.aerial-p.com/media/howtodlchangelly.html',
    coinbase: 'https://www.aerial-p.com/media/coinbase-japan.html',
    coincheck: 'https://www.aerial-p.com/media/howtodlcoincheck.html',
    coinex: 'https://www.aerial-p.com/media/dlhowtocoinex.html',
    coinexchange: 'https://www.aerial-p.com/media/howtodlcoinexchange.html',
    coinsuper: 'https://www.aerial-p.com/media/howtodlcoinsuper.html',
    common: 'https://support.crypto-city.net/hc/ja/articles/360013708992',
    crossexchange: 'https://www.aerial-p.com/media/howtodlcrossexchange.html',
    cryptopia: 'https://www.aerial-p.com/media/howtodlcryptopia.html',
    daedalus: 'https://www.aerial-p.com/media/howtodldaedalus.html',
    decurret: 'https://www.aerial-p.com/media/howtodldecurret.html',
    dmmbitcoin: 'https://www.aerial-p.com/media/howtodldmm-bitcoin.html',
    fisco: 'https://www.aerial-p.com/media/howtodlfisco.html',
    ftx: 'https://www.aerial-p.com/media/howtoddlftx.html',
    fxcoin: 'https://www.aerial-p.com/media/howtodlfxcoin.html',
    gateio: 'https://www.aerial-p.com/media/howtodlgate-io.html',
    ginco: 'https://www.aerial-p.com/media/howtodlginco.html',
    gmocoin: 'https://www.aerial-p.com/media/howtodlgmocoin.html',
    hashhub: 'https://www.aerial-p.com/media/howtodlhashhublending.html',
    hitbtc: 'https://www.aerial-p.com/media/howtodlhitbit.html',
    huobijapan: 'https://www.aerial-p.com/media/howtodlhuobi.html',
    idex: 'https://www.aerial-p.com/media/howtodlidex.html',
    kangaexchange: 'https://www.aerial-p.com/media/kanga-exchange.html',
    kraken: 'https://www.aerial-p.com/media/howtodlkraken.html',
    kucoin: 'https://www.aerial-p.com/media/howtodlkucoin.html',
    ledger: 'https://www.aerial-p.com/media/howtodlledger.html',
    liqui: 'https://www.aerial-p.com/media/howtodlliqui.html',
    liquid: 'https://www.aerial-p.com/media/howtodlquoinex.html',
    mercoin: 'https://www.aerial-p.com/media/howtodlmercoin.html',
    mexc: 'https://www.aerial-p.com/media/howtodlmexcglobal.html',
    nemwallet: 'https://www.aerial-p.com/media/howtodlnanowallet.html',
    nexo: 'https://www.aerial-p.com/media/howtodlnexo.html',
    oasysexplorer: 'https://www.aerial-p.com/media/howtodloasys.html',
    okcoinjapan: 'https://www.aerial-p.com/media/howtodlokcoinjapan.html',
    okx: 'https://www.aerial-p.com/media/howtodlokx.html',
    poloniex: 'https://www.aerial-p.com/media/howtodlpoloniex.html',
    quoinex: 'https://www.aerial-p.com/media/howtodlquoinex.html',
    rakutenwallet: 'https://www.aerial-p.com/media/howtodlrakutenwallet.html',
    xymwallet: 'https://www.aerial-p.com/media/howtodlsymbolwallet.html',
    swissborg: 'https://www.aerial-p.com/media/howtodlswissborg.html',
    taotao: 'https://www.aerial-p.com/media/howtodlsbivc.html',
    tidex: 'https://www.aerial-p.com/media/howtodltidex.html',
    tradesatoshi: 'https://www.aerial-p.com/media/howtodltradesatoshi.html',
    trezor: 'https://www.aerial-p.com/media/howtodltrezor.html',
    yobit: 'https://www.aerial-p.com/media/yobit%E3%81%AE%E5%8F%96%E5%BC%95%E5%B1%A5%E6%AD%B4%E3%81%AE%E5%8F%96%E5%BE%97%E6%96%B9%E6%B3%95.html',
    zaif: 'https://www.aerial-p.com/media/howtodlzaif.html',
    polygonscan: 'https://www.aerial-p.com/media/howtodlpolygonscan.html',
    bscscan: 'https://www.aerial-p.com/media/howtodlbscscan.html',
    etherscan: 'https://www.aerial-p.com/media/howtodlehterscan.html',
    solscan: 'https://www.aerial-p.com/media/howtodlsolscan.html',
    ftmscan: 'https://www.aerial-p.com/media/howtodlftmscan.html',
    snowtrace: 'https://www.aerial-p.com/media/howtodlsnowtrace.html',
    arbiscan: 'https://www.aerial-p.com/media/howtodlarbiscan.html',
    optimistic: 'https://www.aerial-p.com/media/howtodloptimistic.html',
};
