<template>
    <div class="view">
        <GlobalHeader class="header" :show-customer="false" />
        <EnvBar class="env" />
        <nuxt class="page" />
        <GlobalFooter class="footer" />
    </div>
</template>
<script>
import GlobalHeader from '@/components/layout/GlobalHeader.vue';
import GlobalFooter from '@/components/layout/GlobalFooter.vue';
import EnvBar from '@/components/layout/EnvBar.vue';

export default {
    components: {
        GlobalHeader,
        GlobalFooter,
        EnvBar,
    },
    middleware: ['unAuthOnly'],
    mounted() {
        const { cssText } = document.getElementsByTagName('html')[0].style;
        document.getElementsByTagName('html')[0].style.cssText = `${cssText}font-size: 10px !important;`;
    },
};
</script>

<style lang="scss" scoped>
.view {
    min-height: 100vh;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto minmax(0, 1fr) auto;
    grid-template-areas:
        'header'
        'env'
        'page'
        'footer';
}
.env {
    grid-area: env;
}
.header {
    grid-area: header;
}
.page {
    grid-area: page;
}
.footer {
    grid-area: footer;
}
</style>
