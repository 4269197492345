// @ts-check
/** @typedef {import('./types').ServerAmountType} ServerAmountType */
/** @typedef {import('./types').AmountType} AmountType */
/** @typedef {import('./types').ServerJournalSchemaResponse} ServerJournalSchemaResponse */
/** @typedef {import('./types').ServerJournalSchemaItem} ServerJournalSchemaItem */
/** @typedef {import('./types').JournalSchemaMap} JournalSchemaMap */
/** @typedef {import('./types').SchemaMapBody} SchemaMapBody */
/** @typedef {import('./types').JournalSchema} JournalSchema */
/** @typedef {import('./types').Order} Order */

/** @type {Record<AmountType, ServerAmountType>} */
export const AMOUNT_TYPE_KEY = {
    plus: 'value_jpy_current_plus',
    minus: 'value_jpy_cost_minus',
    profitLoss: 'profit_or_loss',
    fee: 'value_jpy_cost_fee',
};

/** @type {Map<ServerAmountType, Order>} */
const orderMap = new Map([
    [AMOUNT_TYPE_KEY.plus, 1],
    [AMOUNT_TYPE_KEY.minus, 1],
    [AMOUNT_TYPE_KEY.profitLoss, 2],
    [AMOUNT_TYPE_KEY.fee, 3],
]);

/**
 * @param {ServerJournalSchemaResponse} raw
 * @returns {JournalSchemaMap}
 */
export const createJournalSchemaMap = (raw) => {
    return Object.entries(raw).reduce((res, [orderType, item]) => {
        res[orderType] = getJournalSchema(item);
        return res;
    }, {});
};

/**
 * @param {ServerJournalSchemaItem} item
 * @returns {SchemaMapBody[]}
 */
const getJournalSchema = (item) => {
    /** @type {Map<Order, JournalSchema>} */
    const map = new Map();

    Object.entries(item).forEach(([creditOrDebit, keys]) => {
        keys.forEach((/** @type {ServerAmountType} */ key) => {
            const order = orderMap.get(key);

            if (!map.has(order)) map.set(order, initialSchema());
            map.get(order)[creditOrDebit] = key;
        });
    });

    return Array.from(map)
        .map(([order, schema]) => ({ order, schema }))
        .sort((a, b) => a.order - b.order);
};

const initialSchema = () => ({
    credit: undefined,
    debit: undefined,
});
