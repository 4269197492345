// @ts-check
/** @typedef {import('./tradeValidation').InputType} InputType */
/** @typedef {import('./tradeValidation').InputRule} InputRule */

/**
 * カスタムルールの参照元： app/Libs/ValidatorUtil.php
 * Laravel搭載のルール一覧： https://laravel.com/docs/9.x/validation#available-validation-rules
 * Laravel搭載のルールのソースコード： https://github.com/lunain84/framework/blob/fa7c923a84ef15e157d46c30f4db10aef6275fd2/src/Illuminate/Validation/Concerns/ValidatesAttributes.php
 */

import { flattenNumStr, toNumber, isNumeric, isInt, overZero } from '@/libs/utils.js';
import { trimIfString, noValueOr } from './utils.js';
import { hasValue, isString, isAlphaNum } from './validationFunctions.js';

export const PASS = () => true;
export const FAIL = () => false;

/**
 * @param {InputType} val
 * @param {number} wholeMax
 * @param {number} fracMax
 * @returns {boolean}
 */
const validateDecimal = (val, wholeMax, fracMax) => {
    if (!hasValue(val)) return true;
    // BigIntに'1e+10'のような指数表記を渡すとエラーになるので、展開する。
    const flattened = flattenNumStr(val);
    if (!isNumeric(flattened)) return false;

    // jsで比較に使える整数の最大値は 2^53 - 1 で、それを超える可能性があるので整数部分だけ切り出してBigIntで扱う
    const [whole] = flattened.split('.');
    return BigInt(whole) < BigInt(10 ** (wholeMax - fracMax));
};

/** @type {InputRule} */
const validateDailyRate = (val) => validateDecimal(val, 20, 8);

/**
 * フロント側で使用しないルールには PASS (trueを返すだけの関数)を渡す。
 * @type {Record<string, InputRule>}
 */
export const staticRules = {
    required: (val) => hasValue(trimIfString(val)),

    // blank、blank_or_zeroは画面上で「入力不可」とし「APIリクエストから省く」ためバリデーションはしない
    blank: PASS,
    blank_or_zero: PASS,

    // requiredとblankでカバーされるので使用しない
    nullable: PASS,

    numeric: noValueOr(isNumeric),
    integer: noValueOr(isInt),
    over_zero: noValueOr(overZero),
    string: noValueOr(isString),
    alpha_num: noValueOr(isAlphaNum),

    // フロントでは気にしなくていいとのことなので使用しない
    exist_exchanger_id: PASS,

    value_jpy_current: validateDailyRate,

    in_jpy: noValueOr((val) => isString(val) && val.toLowerCase() === 'jpy'),

    order_type_unknown: FAIL,

    // same系は、tradeの他のフィールドと関連したチェックになる。
    // 現状フロントでは各フィールド入力時にチェックしており、比較先のフィールドに入力前の時点で比較してエラーを表示するのはUX的に逆にマイナスなので、PASSする。
    // サーバーからのバリデーションエラーをtitleに入れてもらうことで、ユーザーにはエラーが表示される。
    same_amount: PASS,
    same_symbol: PASS,
};

export const dynamicRules = {
    /**
     * 値が数字で、param以上の場合にtrueを返す
     * @param {number} param
     * @returns {InputRule}
     * */
    min: (param) =>
        noValueOr((val) => {
            const num = toNumber(val);
            return num !== undefined && num >= param;
        }),

    /**
     * 値が文字列で、paramより少ない文字数の場合にtrueを返す
     * @param {number} param
     * @returns {InputRule}
     */
    max: (param) => noValueOr((val) => isString(val) && val.length <= param),

    /**
     * 値がparamで指定した(整数 - 小数)の桁で表現できる数字の場合にtrueを返す
     * 例： param: "30,8" であれば、整数部が22桁で表現できる最大の値以下であることを確認する
     * （9,999,999,999,999,999,999,999.99999999...）
     * @param {string} param "整数,小数"
     * @returns {InputRule}
     */
    max_digits_decimal: (param) => (val) => {
        const [wholeMax, fracMax] = param.split(',').map(Number);
        return validateDecimal(val, wholeMax, fracMax);
    },

    /**
     * Dateのフォーマットはコードの仕事なので、ユーザーが入力した値に対しては何もしない
     * @returns {InputRule}
     */
    date_format: (/** "Y-m-d H:i:s" */) => PASS,

    /**
     * 値がparamで指定した配列に含まれる場合にtrueを返す
     * @param {string[]} param
     * @returns {InputRule}
     */
    in: (param) => (val) => param.includes(val),
};
