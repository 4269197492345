// @ts-check
/** @typedef {import('./types').MappedOrderType} OrderType */
/** @typedef {import('./types').ServerValidatedFields} ServerValidatedFields */
/** @typedef {import('./types').ValidatedFields} ValidatedFields */
/** @typedef {import('./types').ValidatedFieldMap} ValidatedFieldMap */

/**
 * @param {*} orderTypes
 * @returns {OrderType[]}
 */
export const deserializeOrderTypes = (orderTypes) => {
    return orderTypes.flatMap((oT) => {
        const mappedOT = mapOrderType(oT);
        return [mappedOT, ...Object.entries(oT.sub_order_type).flatMap((subOT) => mapSubOrderType(mappedOT, subOT))];
    });
};

/**
 * @param {*} oT
 * @returns {OrderType}
 */
const mapOrderType = (oT) => ({
    id: oT.id,
    name: oT.japanese_name,
    manual: oT.is_manually_created,
    unimported: oT.is_selectable_for_unimported_trade,
    pending: oT.is_registerable_for_pending_trade,
    defiManual: oT.is_use_for_defi_manual_register,
    nftManual: oT.is_use_for_nft_manual_register,
    dappsDefi: oT.is_use_for_pending_defi_by_dapps,
    journalRule: oT.is_use_for_journal_rule,
    jpyOnly: oT.jpy_flag,
    ...mapValidatedFields(oT.allowed_fields),
});

/**
 * @param {OrderType} oT
 * @param {[string, *]} subOT
 * @returns {OrderType}
 */
const mapSubOrderType = (oT, [key, subOT]) => ({
    id: `${oT.id}/${key}`,
    name: subOT.japanese_name,
    manual: subOT.is_manually_created,
    unimported: subOT.is_selectable_for_unimported_trade,
    pending: subOT.is_registerable_for_pending_trade,
    defiManual: subOT.is_use_for_defi_manual_register,
    nftManual: subOT.is_use_for_nft_manual_register,
    dappsDefi: subOT.is_use_for_pending_defi_by_dapps,
    journalRule: subOT.is_use_for_journal_rule,
    jpyOnly: oT.jpyOnly,
    allowedFields: oT.allowedFields,
    requiredFields: oT.requiredFields,
});

/**
 *
 * @param {ServerValidatedFields} original
 * @returns { { allowedFields: ValidatedFields, requiredFields: ValidatedFields } }
 */
const mapValidatedFields = (original) => {
    return Object.entries(original).reduce(
        (res, [key, val]) => {
            if (val === 'prohibited') return res;

            // @ts-expect-error
            const fields = validatedFieldsMap.get(key);

            Object.assign(res.allowedFields, fields);
            if (val === 'required') Object.assign(res.requiredFields, fields);

            return res;
        },
        { allowedFields: {}, requiredFields: {} }
    );
};

/** @type {ValidatedFieldMap} */
const validatedFieldsMap = new Map([
    ['plus', { pairPlus: true, amountPlus: true }],
    ['minus', { pairMinus: true, amountMinus: true }],
    ['fee', { pairFee: true, feePair: true }],
    ['value_jpy_current_plus', { valueJpyCurrentPlus: true }],
]);

/**
 * @param {string|undefined} orderType
 * @param {string|undefined} subOrderType
 * @returns {string | undefined}
 */
export const formOrderTypeKey = (orderType, subOrderType) => {
    if (!orderType) return undefined;
    return subOrderType ? `${orderType}/${subOrderType}` : orderType;
};

/**
 * @param {string|undefined} orderTypeKey
 * @returns {(string | undefined)[]} [orderType, subOrderType]
 */
export const splitOrderTypeKey = (orderTypeKey) => (orderTypeKey ? orderTypeKey.split('/') : []);
