<template>
    <div>
        <GlobalHeader class="header" :show-customer="false" />
        <EnvBar />
        <nuxt class="content" />
        <GlobalFooter />
    </div>
</template>
<script>
import GlobalHeader from '@/components/layout/GlobalHeader.vue';
import GlobalFooter from '@/components/layout/GlobalFooter.vue';
import MessageHandler from '@/mixins/MessageHandler.vue';
import EnvBar from '@/components/layout/EnvBar.vue';

export default {
    components: {
        GlobalHeader,
        GlobalFooter,
        EnvBar,
    },
    mixins: [MessageHandler],
    middleware: 'authOnly',
    mounted() {
        const { cssText } = document.getElementsByTagName('html')[0].style;
        document.getElementsByTagName('html')[0].style.cssText = `${cssText}font-size: 10px !important;`;
    },
};
</script>

<style lang="scss" scoped>
.header {
    margin-bottom: 60.8px;
}
</style>

<style lang="scss">
body {
    background-color: #f8f8f8;
    color: #444;
}

// breakpoints
$sp: 600;
$tb: 768;
$pc: 1440;

// media queries
$breakpoints: (
    'sp': 'screen and (max-width: #{$sp}px)',
    'tb': 'screen and (max-width: #{$tb}px)',
    'pc': 'screen and (max-width: #{$pc}px)',
) !default;

// メディアクエリ設定用 mix-in
@mixin mq($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

.dashboad {
    padding-bottom: 9rem;
    @include mq() {
        padding-bottom: 4.7rem;
    }
}
.table {
    @include mq() {
        overflow-x: scroll;
        > img {
            // ここはライブラリで実装時に調整してください
            width: 940px;
        }
    }
}
.btn-download-wrap {
    width: 280px;
    margin-left: auto;
    margin-right: 0;
    @include mq() {
        margin: 0 auto;
    }
}
.graph {
    &:not(:first-child) {
        margin-top: 6rem;
    }
    .graph__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .graph__header-text {
            margin: 0 2.5rem 0 0;
            font-size: 2rem;
            font-weight: bold;
            @include mq() {
                font-size: 1.6rem;
            }
        }
    }
    .graph__body {
        margin-top: 4.5rem;
        @include mq() {
            overflow-x: scroll;
            > img {
                // ここはライブラリで実装時に調整してください
                width: 940px;
            }
        }
    }
}
.piechart-container {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    @include mq() {
        display: block;
    }
    .piechart {
        display: flex;
        flex-direction: column;
        text-align: center;
        flex: 1 1 0px;
        &:not(:first-child) {
            @include mq() {
                margin-top: 5rem;
            }
        }
        .piechart__title {
            font-size: 2rem;
            font-weight: bold;
            color: #333333;
        }
        .piechart__body {
            font-size: 1rem;
            flex: 1 1 0px;
        }
    }
}
</style>
