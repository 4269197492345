// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/design/icon_arrow-left.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/design/icon_arrow-left-disable.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/design/icon_arrow-right.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container[data-v-257e0bb1]{position:relative;display:grid;grid-template-columns:24px 0 minmax(min-content,1fr) auto 0 24px;grid-template-rows:repeat(2,auto);grid-template-areas:\"prev gap1 year closed gap2 next\" \"prev gap1 sub closed gap2 next\";grid-column-gap:16px;-moz-column-gap:16px;column-gap:16px;grid-row-gap:4px;row-gap:4px;line-height:1}.container[data-v-257e0bb1],.year[data-v-257e0bb1]{align-items:center}.year[data-v-257e0bb1]{grid-area:year;display:flex;grid-gap:7px;gap:7px;font-size:1.4rem;color:#2a87e3;letter-spacing:.5px}.close[data-v-257e0bb1],.year[data-v-257e0bb1]{font-weight:700}.close[data-v-257e0bb1]{--clr-main:#2a87e3;--clr-bg:#eaf8fa;grid-area:closed;opacity:1!important;padding:10px 15px;font-size:1.2rem;color:var(--clr-main);background-color:var(--clr-bg);border-color:var(--clr-main)}.close[data-v-257e0bb1]:hover{color:var(--clr-bg);background-color:var(--clr-main);border-color:var(--clr-bg)}.close.closed[data-v-257e0bb1]{--clr-main:#fff;--clr-bg:#30bace}.span-text[data-v-257e0bb1]{grid-area:sub;font-size:1.2rem;color:#666;white-space:nowrap}.button[data-v-257e0bb1]{cursor:pointer;width:100%;height:100%;background-position:50%;background-size:18px 18px;background-repeat:no-repeat}.button.prev[data-v-257e0bb1]{grid-area:prev;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.button.prev-disabled[data-v-257e0bb1]{cursor:default;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.button.prev-disabled .help[data-v-257e0bb1]{position:relative;left:65%;top:1px}.button.next[data-v-257e0bb1]{grid-area:next;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.text-link[data-v-257e0bb1]{text-decoration:underline}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
