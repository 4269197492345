<template>
    <div v-if="!isClosed" class="sp-notice" @click="isClosed = true">
        ※{{ serviceName }}はPCでのご利用を推奨しております。
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            isClosed: false,
        };
    },
    computed: {
        ...mapGetters(['serviceName']),
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/foundation/_mixin.scss';
.sp-notice {
    @include mq() {
        z-index: 100;
        position: fixed;
        bottom: 70px;
        right: 20px;
        background: #f3e675;
        color: #504909;
        padding: 12px;
        border-radius: 3px;
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.6;

        &:after {
            font-family: element-icons !important;
            content: '\e6db';
            position: absolute;
            top: 1px;
            right: 5px;
        }
    }
    @include mq-min() {
        display: none;
    }
}
</style>
