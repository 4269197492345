<template>
    <div class="content">
        <section class="general-page">
            <div class="general-box">
                <div class="inner">
                    <h1>{{ message }}</h1>
                    <p v-if="statusCode === 404" class="sub-message">
                        {{ serviceName }}は、<a href="https://www.google.com/intl/ja_jp/chrome/" target="_blank"
                            >「Google Chrome」</a
                        >でのご利用を推奨しております。<br /><br />
                        「Internet Explorer」及び、「Microsoft Edge」をお使いの方は、<br />
                        「Google Chrome」に切り替えていただくことでエラーが解消する場合がございます。<br /><br />
                        「Google Chrome」をお使いでエラーが発生している場合には、<br />
                        誠に恐れ入りますが、<a href="https://support.crypto-city.net/hc/ja/requests/new"
                            >{{ serviceName }}サポートセンター</a
                        >までご連絡ください。<br />
                    </p>
                    <nuxt-link to="/">
                        <el-button class="btn-general">トップに戻る</el-button>
                    </nuxt-link>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Error',
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    head() {
        return {
            title: this.message,
        };
    },
    computed: {
        ...mapGetters(['serviceName']),
        statusCode() {
            return (this.error && this.error.statusCode) || 500;
        },
        message() {
            return this.statusCode === 404 ? 'ページが見つかりませんでした' : 'エラーが発生しました';
        },
    },
};
</script>

<style scoped>
.inner {
    text-align: center;
}
.inner ::v-deep .btn-general {
    margin-bottom: 5rem;
}
.sub-message {
    background-color: #f5f5f5;
    padding: 20px 0;
    font-style: italic;
}
</style>
