<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MessageHandler',
    computed: {
        ...mapGetters(['getErrorMessage']),
    },
    watch: {
        getErrorMessage(val) {
            if (val.message !== undefined) {
                this.$message.error({ message: val.message });
            }
        },
    },
};
</script>
