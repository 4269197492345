// @ts-check
import { formatDate } from '@/libs/date.js';

const getTextContent = (dom, tagName) => dom.getElementsByTagName(tagName)?.[0]?.textContent;

export const parseRSS = (txt) => {
    const xml = new DOMParser().parseFromString(txt, 'text/xml');
    const rss = xml.getElementsByTagName('item');
    const top5 = Array.from(rss).slice(0, 4);

    return top5.map((row) => {
        const pubDate = getTextContent(row, 'pubDate');
        const formattedPubDate = formatDate(pubDate, '.') ?? '日付不明';

        const linkUrl = new URL(getTextContent(row, 'link'));
        // 不要なsearchParamを排除
        const link = `${linkUrl.origin}${linkUrl.pathname}`;

        return {
            title: getTextContent(row, 'title'),
            link,
            pubDate: formattedPubDate,
        };
    });
};
