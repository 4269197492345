// @ts-check
/** @typedef {import('@/libs/types').ListOption} ListOption */

/**
 * オブジェクトの配列から指定したプロパティの値を取り出し、重複を除いた上でel-selectで使える形で返す。
 * @param {Record<string, any>[]} objList
 * @param {string} targetProp
 * @returns {ListOption[]}
 */
export const createListOptions = (objList, targetProp) => {
    const set = new Set();
    objList.forEach((address) => {
        if (address[targetProp] === undefined) return;
        set.add(address[targetProp]);
    });
    return Array.from(set).map((value) => ({
        id: value,
        name: value,
    }));
};
