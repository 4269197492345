<template>
    <Transition name="el-fade-in">
        <div v-if="trigger" class="container">
            <img class="loading" src="@/assets/images/loading.svg" alt="loading..." />
        </div>
    </Transition>
</template>

<script>
export default {
    props: { trigger: Boolean },
};
</script>

<style scoped>
.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(250, 250, 250, 0.4);
    display: grid;
    place-items: center;
    align-content: center;
    grid-template-rows: minmax(0, 60%);
}
.loading {
    height: 100%;
    width: 33%;
    max-width: 60px;
}
</style>
