// @ts-check
export const planNames = {
    cpta: '税理士',
    corporate_minimum_2021: 'ミニマム',
    corporate_basic_2021: 'ベーシック',
    corporate_professional_2021: 'プロフェッショナル',
};

export const calcTypeNames = {
    yearly_total_average: '総平均法(年次)',
    monthly_total_average: '総平均法(月次)',
    moving_average: '移動平均法',
};
