<template>
    <nuxt-link to="/cpta/customers" class="customer-name">
        {{ $store.getters.getCurrentUser.customer_name }}
        <i class="el-icon-refresh" />
    </nuxt-link>
</template>

<script>
export default {
    name: 'CustomerName',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/foundation/_mixin.scss';
.customer-name {
    padding-right: 1rem;
    @include mq() {
        display: none;
    }
}
</style>
