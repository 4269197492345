<template>
    <div style="display: inline-block">
        <el-popover ref="help" trigger="hover" :visible-arrow="false" popper-class="help">
            <div v-if="htmlContent" v-html="htmlContent" />
            <slot v-else />
        </el-popover>
        <div v-popover:help class="help-image" />
    </div>
</template>

<script>
export default {
    props: {
        htmlContent: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.help-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    scale: 0.95;
    background-color: #8f8f8f;
    background-image: url('@/assets/images/icon_help.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
}
</style>

<style lang="scss">
.el-popover.help {
    max-width: min(75vw, 380px);
    width: max-content;
    padding: 2.4rem;
    border: 1px solid #a8a8a8;
    line-height: 1.8;
    font-size: 1.3rem;
    color: #333;

    b {
        color: #659fd1;
    }
}
</style>
