// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

import { toDate } from './base.js';
import { getStartOfDay } from './get.js';

/**
 * １つ目の日付に対して、２つ目の日付が何ms後（前）かを返す
 * @param {DateInput} date1
 * @param {DateInput} date2
 * @returns {number | undefined}
 */
export const differenceInMs = (date1, date2) => {
    const d1 = toDate(date1);
    const d2 = toDate(date2);
    if (d1 === undefined || d2 === undefined) return undefined;

    const diff = d2.getTime() - d1.getTime();
    return diff;
};

/**
 * １つ目の日付に対して、２つ目の日付が何日後（前）かを返す
 * @param {DateInput} date1
 * @param {DateInput} date2
 * @returns {number | undefined}
 */
export const differenceInDays = (date1, date2) => {
    const startOfDay1 = getStartOfDay(date1);
    const startOfDay2 = getStartOfDay(date2);
    if (startOfDay1 === undefined || startOfDay2 === undefined) return undefined;

    return (startOfDay2.getTime() - startOfDay1.getTime()) / (1000 * 60 * 60 * 24);
};

/**
 * 渡した日時が現在より過去かどうかを返す
 * @param {DateInput} date
 * @returns {boolean}
 */
export const isPast = (date) => {
    const d = toDate(date);
    if (d === undefined) return warnAndReturn(`Invalid date - arg1 date: ${date}`);

    return d.getTime() < Date.now();
};

/**
 * 渡した日時が現在より過去かどうかを返す
 * @param {DateInput} date
 * @returns {boolean}
 */
export const isFuture = (date) => {
    const d = toDate(date);
    if (d === undefined) return warnAndReturn(`Invalid date - arg1 date: ${date}`);

    return d.getTime() > Date.now();
};

/**
 * １つ目の日付が２つ目以降かつ3つ目以前かどうかを返す。
 * @param {DateInput} date
 * @param {DateInput} from
 * @param {DateInput} to
 * @returns {boolean}
 */
export const isBetween = (date, from, to) => {
    const d = toDate(date);
    const f = toDate(from);
    const t = toDate(to);
    if (d === undefined) return warnAndReturn(`Invalid date - arg1 date: ${date}`);
    if (f === undefined) return warnAndReturn(`Invalid date - arg2 from: ${from}`);
    if (t === undefined) return warnAndReturn(`Invalid date - arg3 to: ${to}`);

    return f.getTime() <= d.getTime() && d.getTime() <= t.getTime();
};

/**
 * 一つ目の日付が、二つ目の日付より前かどうかを返す。
 * @param {DateInput} date
 * @param {DateInput} baseDate
 * @returns {boolean}
 */
export const isBefore = (date, baseDate) => {
    const diff = differenceInMs(baseDate, date);
    if (diff === undefined) warnAndReturn(`Invalid date\narg1 date: ${date}\narg2 baseDate: ${baseDate}`);

    return diff < 0;
};

/**
 * 一つ目の日付が、二つ目の日付より後かどうかを返す。
 * @param {DateInput} date
 * @param {DateInput} baseDate
 * @returns {boolean}
 */
export const isAfter = (date, baseDate) => {
    const diff = differenceInMs(baseDate, date);
    if (diff === undefined) warnAndReturn(`Invalid date\narg1 date: ${date}\narg2 baseDate: ${baseDate}`);

    return diff > 0;
};

/**
 * 渡した日時の中から一番未来の日時を返す
 * @param  {DateInput[]} dates
 * @returns {Date | undefined}
 */
export const pickLatestDate = (...dates) => {
    return new Date(Math.max(...dates.map((d) => toDate(d)?.getTime() ?? 0)));
};

/**
 * 渡した日時の中から一番過去の日時を返す
 * @param  {DateInput[]} dates
 * @returns {Date | undefined}
 */
export const pickOldestDate = (...dates) => {
    return new Date(Math.min(...dates.map((d) => toDate(d)?.getTime() ?? 0)));
};

/**
 * @param {string} message
 * @returns {undefined}
 */
const warnAndReturn = (message) => {
    // eslint-disable-next-line no-console
    console.warn(message);
    return undefined;
};
