<template>
    <div v-if="shouldBeVisible" class="bar" :class="$config.appEnv">
        {{ $config.appEnv.toUpperCase() }}
    </div>
    <div v-else-if="isForceLogin" class="bar forceLogin">管理者権限でログイン中です</div>
</template>
<script>
import { clientStorage, STORAGE_KEYS } from '@/libs/storage.js';

export default {
    name: 'EnvBar',
    computed: {
        shouldBeVisible() {
            return !['production', 'local'].includes(this.$config.appEnv);
        },

        isForceLogin() {
            return this.$config.appEnv === 'production' && clientStorage.get(STORAGE_KEYS.isForceLogin);
        },
    },
};
</script>

<style scoped>
.bar {
    text-align: center;
    padding: 6px 0 5px;
    font-size: 15px;
    letter-spacing: 1.5px;
}
[class*='stg'] {
    background: lightblue;
}
[class*='dev'] {
    background: lightgreen;
}
.forceLogin {
    background: lightcoral;
}
</style>
