// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

/**
 * @param {Date} date
 * @returns {boolean}
 */
export const isValidDate = (date) => date instanceof Date && !Number.isNaN(date.getTime());

/**
 * 渡した日時をDateオブジェクトで返す
 * @param {DateInput} date
 * @returns {Date | undefined}
 */
export const toDate = (date) => {
    // new Date()に渡した値は、nullの場合は０として扱われ、undefinedの場合は現在の日付が返る。
    // この関数では、有効な日付を渡した時以外はundefinedを返すようにする。
    if (!date) return undefined;

    const parsed = typeof date === 'string' ? localParse(date) : new Date(date);
    if (!isValidDate(parsed)) return undefined;

    return parsed;
};

/**
 * 時間を指定しないisoString形式の日付を素直にnew Date()に渡すと、UTCの入力と判断される。
 * その結果、例えば2020-01-01を渡すと、2020-01-01T00:00:00Z、日本時間だと2020-01-01 09:00:00となってしまう。
 * また、ユーザーが海外にいるとき、タイムゾーンの違いにより日付がずれ、サーバーとのやり取りがうまくいかないことがある。
 * この関数では、入力が常に日本時間として扱われるようにする。
 * @param {string} date
 * @returns {Date | undefined}
 */
const localParse = (date) => {
    // iso形式でない場合、この関数では対応しない
    const isInISOformat = /^\d{4}\D\d{1,2}\D\d{1,2}/.test(date);
    if (!isInISOformat) return new Date(date);

    // 元々UTCで指定されている場合、変換は不要
    const isUTC = date.slice(-1) === 'Z';
    if (isUTC) return new Date(date);

    // 以下、現地時間で指定されている場合の処理。
    // まず日付をUTCにしてから９時間引いて、日本時間に変換する。

    // 1) 日付を数字以外の文字で分割
    const ds = date.split(/\D/).map(Number);
    // 2) 月は0から始まるので、1引いて調整
    ds[1] -= 1;
    // @ts-ignore 3)  一度UTCとしてDateオブジェクトを作成する
    const d = new Date(Date.UTC(...ds));
    // 4) UTCから日本時間(-9h)に変換する
    d.setHours(d.getHours() - 9);
    return d;
};
