const prop2label = new Map([
    ['tradedAt', '日時'],
    ['exchangerId', '取引所'],
    ['orderTypeKey', '取引種別'],
    ['pairPlus', '(+)取引通貨'],
    ['amountPlus', '(+)取引量'],
    ['pairMinus', '(-)取引通貨'],
    ['amountMinus', '(-)取引量'],
    ['pairFee', '手数料通貨'],
    ['feePair', '手数料額'],
    ['valueJpyCurrentPlus', '時価'],
]);

export const shapeMessage = (message, prop) => {
    // TODO: :attribute以外の置換も必要なら、paramを保存しておく必要がある。
    const placeholders = {
        ':attribute': prop,
        // ':min': param,
        // ':max': param
    };

    return message.replace(buildRegExp(Object.keys(placeholders)), (match) => prop2label.get(placeholders[match]));
};

const buildRegExp = (list) => new RegExp(list.join('|'), 'g');
