<template>
    <li ref="item" class="list-item" :class="{ restricted: item.isRestricted }" :title="isCollapsed ? item.label : ''">
        <div v-if="item.isRestricted" class="link">
            <img :src="item.icon" class="icon" />
            <span v-show="!isCollapsed">{{ item.label }}</span>
        </div>
        <a v-else-if="item.id.startsWith('https')" :href="item.id" class="link" target="_blank">
            <img :src="item.icon" class="icon" />
            <span v-show="!isCollapsed">{{ item.label }}</span>
        </a>
        <nuxt-link v-else :to="item.id" class="link">
            <img :src="item.icon" class="icon" />
            <RedPoint :on="item.alert" class="alert" :class="{ isCollapsed }">
                <span v-show="!isCollapsed">{{ item.label }}</span>
            </RedPoint>
        </nuxt-link>

        <Transition name="sub">
            <ul
                v-if="!item.isRestricted && item.sub"
                v-show="subVisible"
                ref="subMenu"
                class="sub-menu"
                :class="{ hovered, solo: item.sub.length == 1 }"
            >
                <li v-for="subItem in item.sub" :key="subItem.label" class="sub-item">
                    <nuxt-link :to="subItem.id" class="sub-link">
                        {{ subItem.label }}
                    </nuxt-link>
                </li>
            </ul>
        </Transition>
    </li>
</template>

<script>
import RedPoint from '@/components/RedPoint.vue';

export default {
    components: {
        RedPoint,
    },
    props: {
        item: { type: Object, required: true },
        isCollapsed: { type: Boolean },
    },
    data() {
        return {
            hovered: false,
            subVisible: false,
            timeout: undefined,
        };
    },
    mounted() {
        if (!this.item.sub && !this.item.isRestricted) return;
        this.$refs.item.addEventListener('mouseenter', this.onHover);
    },
    beforeDestroy() {
        this.$refs.item.removeEventListener('mouseenter', this.onHover);
    },
    methods: {
        onHover(e) {
            this.$refs.item.addEventListener('mouseleave', this.onLeave, { once: true });
            this.hovered = true;
            this.setSubVisibility(true);
            this.fireMouseEnterEvent(e);
        },
        onLeave() {
            this.hovered = false;
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                if (!this.hovered) this.setSubVisibility(false);
            }, 250);
        },
        setSubVisibility(visibility) {
            if (!this.item.isRestricted && this.item.sub) this.subVisible = visibility;
        },
        fireMouseEnterEvent(event) {
            if (this.item.isRestricted)
                this.$store.commit('showProhibitedTooltip', {
                    event,
                    position: 'right',
                    sideOffset: -15,
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.list-item {
    position: relative;
    width: 100%;
    height: 100%;
    list-style: none;
    white-space: nowrap;

    &:not(.restricted) {
        &:before {
            content: '';
            position: absolute;
            height: 2px;
            bottom: 10px;
            left: 20%;
            right: 20%;
            background-color: #fff;
            opacity: 0.2;
            border-radius: 50%;
            transform: scaleX(0);
            transform-origin: center;
            transition: transform var(--duration);
        }

        &:hover {
            &:before {
                transform: scaleX(1);
            }
        }
    }

    .link {
        position: relative;
        height: 100%;
        display: grid;
        grid-template-columns: 45px minmax(0, 1fr);
        justify-items: start;
        align-items: center;
        color: #fff;
        line-height: 1;

        .alert {
            &::after {
                transition: transform var(--duration);
            }
            &.isCollapsed::after {
                transform: translate(-10px, -10px);
            }
        }
    }

    &.restricted .link {
        cursor: not-allowed;
        background-color: rgba(255, 255, 255, 0.3);
        transition: none;
        opacity: 1 !important;
    }

    &:not(.restricted) .link {
        transition: background-color 0.5s, opacity var(--duration);
        background-color: transparent;

        &:hover {
            opacity: 0.85;
        }

        &.nuxt-link-active {
            background-color: #2a5b9d !important;
        }
    }

    .icon {
        margin-left: 5px;
        width: 18px;
        height: 18px;
        justify-self: center;
    }
}

.sub-menu {
    position: absolute;
    z-index: 1;
    bottom: -60px;
    left: 70%;
    background-color: #fff;
    border-radius: 10px;
    padding: 8px 0;
    box-shadow: 0px 0px 12px rgba(0, 91, 162, 0.1);

    &.solo {
        // サブメニューが一つしかない場合の位置調整
        bottom: -10px;
    }
}
.sub-item {
    width: 100%;
    list-style: none;

    .sub-link {
        display: inline-block;
        padding: 15px 19px 14px;
        width: 100%;
        color: #3369b2;
        transition: background-color var(--duration);

        &:hover {
            background-color: #eaeaea;
            opacity: 1;
        }
    }
}
</style>
