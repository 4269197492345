// @ts-check
/** @typedef {(v: any) => boolean} ValidationFn */

import { hasValue } from '../../validators/validationFunctions.js';

/** @type {ValidationFn} */
export const isValidNumber = (maybeNum) => ['number', 'bigint'].includes(typeof maybeNum) && !Number.isNaN(maybeNum);

/**
 * 有効な数値に変換可能な文字列を数値に変換する。
 * 有効な数値が渡されたときは、そのまま返す。
 * 無効な数値、数値でも文字列でもない値、有効な数値に変換できない文字列が渡されたときはundefinedを返す。
 * @param {string|number} val
 * @returns {number | undefined}
 */
export const toNumber = (val) => {
    if (!isValidNumber(val) && typeof val !== 'string') return undefined;

    const flattened = flattenNumStr(val);
    if (!hasValue(flattened)) return undefined;
    const num = Number(flattened);
    if (!isValidNumber(num) || num !== Number(flattened)) return undefined;
    return num;
};

/** @type {ValidationFn} */
export const isNumeric = (val) => toNumber(val) !== undefined;

/** @type {ValidationFn} */
export const isInt = (val) => {
    const num = toNumber(val);
    return num !== undefined && Number.isInteger(num);
};

/** @type {ValidationFn} */
export const overZero = (val) => {
    const num = toNumber(val);
    return num !== undefined && num >= 0;
};

/**
 * 1) 指数表記をプレーンな数字の表記に変換する。
 * e+とe-に対応、Numberでは扱えない大きな数字にも対応。
 * 2) カンマを除去する。
 * @param {*} num
 * @returns {string}
 */
export const flattenNumStr = (num) => {
    const ripped = num?.toString().replace(/,/g, '').trim();
    if (!ripped || !ripped.match(/(e|E)/)) return ripped;

    const formatter = Intl.NumberFormat('ja-JP', { useGrouping: false, maximumFractionDigits: 8 });
    return formatter.format(ripped);
};
