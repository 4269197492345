// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

import { stringifyDate } from './utils.js';
import { toDate } from './base.js';
import { formatDate } from './format.js';

/**
 * 指定した年月の月初日時(0時0分)を返す
 * @param {number} year
 * @param {number} month
 * @returns {Date | undefined}
 */
export const getFirstDateOfMonth = (year, month) => {
    return toDate(stringifyDate(year, month, 1));
};

/**
 * 指定した年月の前月の月末日時(0時0分)を返す
 * @param {number} year
 * @param {number} month
 * @returns {Date | undefined}
 */
export const getLastDateOfPreviousMonth = (year, month) => {
    return toDate(stringifyDate(year, month, 0));
};

/**
 * 指定した年月を年度末とした年度初日時(0時0分)を返す
 * @param {number} year
 * @param {number} accountingMonth
 * @returns {Date | undefined}
 */
export const getPeriodStart = (year, accountingMonth) => {
    const startMonth = accountingMonth === 12 ? 1 : accountingMonth + 1;
    return getFirstDateOfMonth(year, startMonth);
};

/**
 * 指定した年月を年度初めとした年度末日時(0時0分)を返す
 * @param {number} year
 * @param {number} accountingMonth
 * @returns {Date | undefined}
 */
export const getPeriodEnd = (year, accountingMonth) => {
    const endYear = accountingMonth === 12 ? year : year + 1;
    return toDate(stringifyDate(endYear, accountingMonth + 1, 0));
};

/**
 * 指定した年月を年度締めとした年度開始日(0時0分)と年度末日(0時0分)を返す
 * @param {number} year
 * @param {number} accountingMonth
 * @returns {{ from: Date | undefined, to: Date | undefined }}
 */
export const getPeriodRange = (year, accountingMonth) => {
    const from = getPeriodStart(year, accountingMonth);
    const to = getPeriodEnd(year, accountingMonth);
    return { from, to };
};

/**
 * 指定した年月を年度締めとした年度開始日(0時0分)と年度末日(0時0分)を返す
 * ３つ目の引数で日付の区切り文字を指定できる（デフォルトは'-')。
 * @param {number} year
 * @param {number} accountingMonth
 * @param {string} [splitter='-']
 * @returns {{ from: string | undefined, to: string | undefined }}
 */
export const getFormattedPeriodRange = (year, accountingMonth, splitter = '-') => {
    const from = getPeriodStart(year, accountingMonth);
    const to = getPeriodEnd(year, accountingMonth);
    return { from: formatDate(from, splitter), to: formatDate(to, splitter) };
};

/**
 * 渡した日時の日本時間の0時0分0秒を返す
 * @param {DateInput} date
 * @returns {Date | undefined}
 */
export const getStartOfDay = (date) => {
    // formatDateを通してからtoDateに渡すことで、ユーザーのタイムゾーンに関わらず日本時間の0時0分0秒にできる
    const d = formatDate(date);
    if (d === undefined) return undefined;

    return toDate(d);
};
