import Vue from 'vue';
import lang from 'element-ui/lib/locale/lang/ja.js';
import locale from 'element-ui/lib/locale/index.js';
import ElementUI, { Message } from 'element-ui';

locale.use(lang);
Vue.use(ElementUI);

const defaultOptions = { showClose: true, duration: 3000 };

/**
 * 下記どちらの$messageコールにも対応するよう成形する関数
 * $message('メッセージをテキストで渡すパターン')
 * $message({ message: 'メッセージをオブジェクトで渡すパターン' })
 */
const formatArgs = ([msgOrOptions, options = {}]) => {
    return typeof msgOrOptions === 'string'
        ? { ...options, ...defaultOptions, message: msgOrOptions }
        : { ...defaultOptions, ...msgOrOptions };
};

Vue.prototype.$message = (...args) => Message(formatArgs(args));
Vue.prototype.$message.info = (...args) => Message.info(formatArgs(args));
Vue.prototype.$message.success = (...args) => Message.success(formatArgs(args));
Vue.prototype.$message.warning = (...args) => Message.warning(formatArgs(args));
Vue.prototype.$message.error = (...args) => Message.error(formatArgs(args));
