// @ts-check
/** @typedef {import('./tradeValidation').InputType} InputType */
/** @typedef {import('./tradeValidation').InputRule} InputRule */

import { hasValue } from './validationFunctions.js';

/**
 * 指定したルールに一致しない場合にtrueを返す
 * @type {(fn: InputRule) => (val: InputType) => boolean}
 * */
export const not = (fn) => (val) => !fn(val);

/**
 * 指定したルールのいずれかに一致する場合にtrueを返す
 * @type {(...fns: InputRule[]) => InputRule}
 * */
export const anyTrue =
    (...fns) =>
    (val) =>
        fns.some((fn) => fn(val));

/**
 * 値がないか、指定したルールのいずれかに一致する場合にtrueを返す
 * @type {(...fns: (InputRule)[]) => InputRule}
 * */
export const noValueOr = (...fns) => anyTrue(not(hasValue), ...fns);

/**
 * 渡した値が文字列の場合は前後の空白を削除する
 * @param {InputType} val
 * @returns {InputType}
 */
export const trimIfString = (val) => (typeof val === 'string' ? val.trim() : val);
