// @ts-check
/** @typedef {import('./tradeValidation').ValidatableKeys} ValidatableKeys */
/** @typedef {import('./tradeValidation').Trade} Trade */
/** @typedef {import('./tradeValidation').ValidationConfig} ValidationConfig */
/** @typedef {import('./tradeValidation').RuleSet} RuleSet */
/** @typedef {import('./tradeValidation').RuleObj} RuleObj */
/** @typedef {import('./tradeValidation').SourceRuleObj} SourceRuleObj */
/** @typedef {import('./tradeValidation').OrderType} OrderType */
/** @typedef {import('./tradeValidation').OrderTypeKey} OrderTypeKey */
/** @typedef {import('./tradeValidation').TradeKey} TradeKey */
/** @typedef {import('./tradeValidation').RuleName} RuleName */
/** @typedef {import('./tradeValidation').StringStyleRuleSource} StringStyleRuleSource */
/** @typedef {import('./tradeValidation').ObjectStyleRuleSource} ObjectStyleRuleSource */
/** @typedef {import('./tradeValidation').InputType} InputType */
/** @typedef {import('./tradeValidation').InputRule} InputRule */
/** @typedef {import('./tradeValidation').TradeValidationResult} TradeValidationResult */
/** @typedef {import('./tradeValidation').FieldError} FieldError */
/** @typedef {import('./tradeValidation').RuleEditor} RuleEditor */

import { snakeToCamel } from '@/libs/utils.js';
import { notifySentry } from '@/libs/reporter.js';
import { splitOrderTypeKey } from '@/libs/adapters/orderTypes/index.js';
import { hasValue } from './validationFunctions.js';
import { PASS, staticRules, dynamicRules } from './tradeRules.js';
import { shapeMessage } from './messageShaper.js';

const ORDERTYPE_KEY = 'orderTypeKey';
const FEE_CURRENCY = 'pairFee';
const FEE_AMOUNT = 'feePair';

export class TradeValidator {
    /** @type {Map<OrderType, RuleSet>} */
    rulesets = new Map();

    /** @param {ValidationConfig} config */
    constructor(config) {
        this.#generateruleSets(config);
    }

    /** @param {ValidationConfig} config */
    #generateruleSets({ RULE_OF_ITEMS, RULE_UNIONS }) {
        Object.entries(RULE_OF_ITEMS).forEach(([oT, obj]) => {
            const rulesets = createRulesets(obj, RULE_UNIONS);
            this.rulesets.set(oT, rulesets);
        });
    }

    /**
     * 単純に一つの項目をチェックする。
     * 取引の他の項目に応じたチェックは入らない。
     * @param {OrderTypeKey} orderTypeKey
     * @param {ValidatableKeys} key
     * @param {InputType} value
     * @returns {FieldError|undefined} errorMessage?
     */
    singleValidate(orderTypeKey, key, value) {
        const ruleset = this.#getRuleset(orderTypeKey);
        const rules = getRules(ruleset, key);
        return runValidation(key, value, rules);
    }

    /**
     * 「取引の中の一つの項目」としてチェックする。
     * 他の項目に応じてルールの変更を可能にするため、チェックする値ではなく取引を渡す。
     * @param {Trade} trade
     * @param {TradeKey} key
     * @returns {FieldError|undefined} errorMessage?
     */
    validateField(trade, key) {
        const ruleset = this.#getRuleset(trade.orderTypeKey);
        let rules = getRules(ruleset, key);
        rules = addRequiredConditionally(rules, key, trade);

        return runValidation(key, trade[key], rules);
    }

    /**
     * ひとつの取引を丸ごとチェックする
     * @param {Trade} trade
     * @returns {TradeValidationResult}
     */
    validateTrade(trade) {
        const ruleset = this.#getRuleset(trade.orderTypeKey);
        return Object.keys(trade).reduce((messages, key) => {
            let rules = getRules(ruleset, key);
            rules = addRequiredConditionally(rules, key, trade);

            const message = runValidation(key, trade[key], rules);
            if (message) messages[key] = message;

            return messages;
        }, {});
    }

    /**
     * @param {OrderTypeKey} orderTypeKey
     * @returns {RuleSet}
     */
    #getRuleset(orderTypeKey) {
        const orderType = splitOrderTypeKey(orderTypeKey)[0];
        return this.rulesets.get(orderType);
    }
}

/**
 * @param {TradeKey} key
 * @param {InputType} value
 * @param {RuleObj[]} rules
 * @returns {FieldError|undefined} errorMessage?
 */
const runValidation = (key, value, rules) => {
    for (const { rule, message } of rules) {
        const isValid = rule(value);
        if (!isValid) return shapeMessage(message, key);
    }
};

/** @type {RuleObj} */
const RULEOBJ_REQUIRED = { rule: staticRules.required, message: ':attributeを指定してください' };

/**
 * @param {Record<TradeKey, RuleName[]>} obj
 * @param {ValidationConfig['RULE_UNIONS']} unions
 * @returns {RuleSet}
 */
const createRulesets = (obj, unions) =>
    Object.entries(obj).reduce((res, [key, ruleNames]) => {
        res[snakeToCamel(key)] = ruleNames.flatMap((ruleName) => {
            const rules = unions[ruleName];
            return rules.map(ruleName2rule);
        });
        return res;
    }, {});

/**
 * @param {SourceRuleObj} obj
 * @returns {RuleObj}
 */
const ruleName2rule = (obj) => ({
    ...obj,
    rule: typeof obj.rule === 'string' ? createStaticRule(obj.rule) : createDynamicRule(obj.rule),
});

/**
 * @param {StringStyleRuleSource} ruleName
 * @returns {InputRule}
 */
const createStaticRule = (ruleName) => {
    const rule = staticRules[ruleName];
    return rule || assignPassRuleWithWarning(ruleName);
};

/**
 * @param {ObjectStyleRuleSource} ruleNameObj
 * @returns {InputRule}
 */
const createDynamicRule = (ruleNameObj) => {
    const [rule] = Object.entries(ruleNameObj).map(([name, param]) => {
        const ruleCandidate = dynamicRules[name];
        return ruleCandidate ? ruleCandidate(param) : assignPassRuleWithWarning(name);
    });
    return rule;
};

/**
 * @param {RuleName} ruleName
 * @returns {typeof PASS}
 */
const assignPassRuleWithWarning = (ruleName) => {
    notifySentry(`定義されていないバリデーションルールです。スルーします： ${ruleName}`);
    return PASS;
};

/**
 * @param {RuleSet} rulesets
 * @param {TradeKey} key
 * @returns {RuleObj[]}
 */
const getRules = (rulesets, key) => rulesets?.[key] ?? [{ rule: PASS, message: '' }];

/**
 * 手数料に関しては、通貨/数量のどちらかが入力されていたらフロント側ではもう片方も必須とする。
 * @type {RuleEditor}
 */
export const addRequiredConditionally = (originalRules, key, trade) => {
    return isOtherPairNonEmpty(trade, key) || key === ORDERTYPE_KEY
        ? [RULEOBJ_REQUIRED, ...originalRules]
        : originalRules;
};

/**
 * @param {Trade} trade
 * @param {TradeKey} key
 */
const isOtherPairNonEmpty = (trade, key) =>
    (key === FEE_CURRENCY && hasValue(trade[FEE_AMOUNT])) || (key === FEE_AMOUNT && hasValue(trade[FEE_CURRENCY]));
