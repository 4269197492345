<template>
    <el-button class="submit-btn" :class="color" :disabled="disabled" :loading="loading" @click="$emit('click')">
        <slot />
    </el-button>
</template>

<script>
export default {
    props: {
        color: { type: String, default: () => 'primary' }, // primary || danger || payment
        disabled: { type: Boolean, default: () => false },
        loading: { type: Boolean, default: () => false },
    },
};
</script>

<style lang="scss" scoped>
.submit-btn {
    margin: 0;
    min-width: 18rem;
    padding: 1.7rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    border: none;
    transition-property: opacity, color;

    &.primary {
        background-image: linear-gradient(to right, #37b6cb, #5473e4);
        &:focus-visible {
            outline: 1px solid #37b6cb;
            outline-offset: 2px;
        }
    }
    &.danger {
        background-color: #ff6969;
        &:focus-visible {
            outline: 1px solid #ff6969;
            outline-offset: 2px;
        }
    }
    &.payment {
        background-image: linear-gradient(to right, #ff9446, #fc227e);
        &:focus-visible {
            outline: 1px solid #ff9446;
            outline-offset: 2px;
        }
    }

    &:not(.is-disabled):hover {
        opacity: 0.6;
    }
    &.is-disabled {
        color: #ccc;
        background: #fff;
        outline: solid 1px #e8e8e8;
        outline-offset: -1px;
    }
}
</style>
