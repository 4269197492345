<template>
    <header class="global-header" :class="{ 'visible-chat-tag-manager': getIsRegularUser }">
        <ServiceNotice />

        <div class="header__inner">
            <div class="logo" :class="serviceName" @click="goTop()">
                <img v-if="serviceName === 'Gtax'" src="@/assets/images/design/logo.svg" :alt="serviceName" />
                <img v-else src="@/assets/images/top/awa/awa_logo.png" :alt="serviceName" />
            </div>

            <Period v-if="periodVisible" class="period" />

            <div class="menu">
                <CustomerName v-if="getIsCpta && showCustomer" />

                <div v-if="getIsCreator" class="header-column">{{ getAddress }}</div>

                <PulldownMenu v-if="isLoggedIn" />
                <template v-else>
                    <nuxt-link v-if="currentPath !== '/signin'" to="/signin" class="login-link"> ログイン </nuxt-link>
                    <div v-if="currentPath !== '/signup'" class="header__btn-siginup">
                        <nuxt-link to="/signup" class="btn-signup">新規登録(無料)</nuxt-link>
                    </div>
                </template>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Period from '@/components/layout/Period.vue';
import PulldownMenu from '@/components/layout/GlobalHeader/PulldownMenu.vue';
import ServiceNotice from './ServiceNotice.vue';
import CustomerName from './GlobalHeader/CustomerName.vue';

export default {
    name: 'GlobalHeader',
    components: {
        ServiceNotice,
        Period,
        CustomerName,
        PulldownMenu,
    },
    props: {
        showCustomer: { type: Boolean, default: false },
        showPeriod: { type: Boolean, default: () => false },
    },
    data() {
        return {
            showProfitSimulation: false,
        };
    },
    computed: {
        ...mapGetters([
            'getHomeURL',
            'isLoggedIn',
            'serviceName',
            'getIsCpta',
            'getCalcStatus',
            'getIsRegularUser',
            'getPlan',
            'getCurrentUser',
            'getIsCreator',
            'getAddress',
        ]),

        currentPath() {
            return this.$route.path;
        },

        campaignLink() {
            if (this.getCurrentUser.created_at < '2021-10-15 00:00:00') {
                return 'https://www.aerial-p.com/media/gtax-2020-free-campaign.html';
            }
            return 'https://www.aerial-p.com/media/gtax-trial-campaign.html?utm_source=gtax&utm_medium=pop-up&utm_campaign=otameshi';
        },
        periodVisible() {
            return this.showPeriod && this.getYear !== null;
        },
    },
    methods: {
        goTop() {
            this.$router.push(this.getHomeURL);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/foundation/_mixin.scss';

.global-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;

    .header__inner {
        position: relative;
        height: 60.8px;
        display: grid;
        grid-template-columns: 1fr auto minmax(max-content, 1fr);
        grid-template-areas: 'logo period menu';
        align-items: center;
        padding: 0 3rem;
        background-color: #f8f8f8;

        @include mq() {
            padding: 1rem 1rem;
        }

        .logo {
            grid-area: logo;
            cursor: pointer;

            &.AWA {
                // 現状AWAのワンポイントロゴがないので、CSSでフルロゴの「A」のみ表示するよう調整
                overflow: hidden;
                border-top-right-radius: 18px;
                img {
                    width: 300%;
                }
            }
        }
        .period {
            grid-area: period;
        }
        .menu {
            grid-area: menu;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include mq() {
                padding: 0px 15px;
            }
        }

        .btn-signup {
            @include btn-base;
            width: 120px;
            padding: 14px 0;
            background: linear-gradient(to right, rgb(255, 148, 70) 0%, rgb(252, 34, 126) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9446', endColorstr='#fc227e',GradientType=1 ); /* IE6-9 */
            font-size: 1.4rem;
            color: #fff;
            &:hover,
            &:visited {
                color: #fff;
            }
        }
        &:after {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            display: block;
            content: '';
            height: 4px;
            @include bgc-blue;
        }
    }
}
.login-link {
    padding-right: 2rem;
}
.header-column {
    margin: 0 1em;
    position: relative;
    top: 1px;
}

.line {
    color: #467ce3;
}
</style>
