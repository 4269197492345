// @ts-check
import { clientStorage, STORAGE_KEYS } from '@/libs/storage.js';

export default ({ $axios, store }) => {
    // 税理士版で顧客を選択しているときはあらゆるリクエストに顧客IDを追加する
    $axios.onRequest((config) => {
        const customerId = store.getters.getCustomerUserId;
        if (!customerId) return;

        if (!config.params) config.params = {};
        config.params.user_id = customerId;
    });

    $axios.onResponseError((err) => {
        // プランのアップグレードが必要
        if (err.response?.data.code === 100015) {
            store.commit('setUpgradeMessage', err.response.data.details[0]);
        }
        // 認証切れになったときはブラウザをリロード
        if (err.response?.status === 401 && store.state.hasToken) {
            clientStorage.remove(STORAGE_KEYS.accessToken);
            window.location.reload();
            return Promise.resolve(false);
        }
    });
};
