<template>
    <div class="view">
        <nuxt class="content" />
        <EnvBar class="env" />
    </div>
</template>
<script>
import EnvBar from '@/components/layout/EnvBar.vue';

export default {
    components: {
        EnvBar,
    },
};
</script>

<style scoped>
.env {
    position: fixed;
    top: 68px;
    width: 100%;
    z-index: 10;
}
</style>
