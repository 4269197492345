// @ts-check
/** @typedef { typeof STORAGE_KEYS['accessToken'] } StorageConfig */
import { STORAGE_KEYS, deprecatedKeys } from './const.js';

export const clientStorage = {
    /**
     * @param {StorageConfig} config
     * @returns {*}
     */
    get: ({ key, storage }) => {
        const value = storage.getItem(key);
        return value != null ? JSON.parse(value) : undefined;
    },
    /**
     * @param {StorageConfig} config
     * @param {*} value
     * @returns {void}
     */
    set: ({ key, storage }, value) => {
        storage.setItem(key, JSON.stringify(value));
    },
    /**
     * @param {StorageConfig} config
     * @returns {void}
     */
    remove: ({ key, storage }) => {
        storage.removeItem(key);
    },
    /** @returns {void} */
    cleanUp() {
        deprecatedKeys.forEach(({ key, storage }) => {
            if (!(key in storage)) return;
            clientStorage.remove({ key, storage });
        });
    },
};

export { STORAGE_KEYS };
