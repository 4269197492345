// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

import { toDate } from './base.js';

/**
 * 日付に日数を足した日時を返す。日数が負の場合は引く。
 * @param {DateInput} date
 * @param {number} seconds
 * @returns {Date | undefined}
 */
export const addSeconds = (date, seconds) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    return new Date(d.getTime() + seconds * 1000);
};

/**
 * 日付に日数を足した日時を返す。日数が負の場合は引く。
 * @param {DateInput} date
 * @param {number} days
 * @returns {Date | undefined}
 */
export const addDays = (date, days) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    d.setDate(d.getDate() + days);
    return d;
};

/**
 * 日付に月数を足した日時を返す。月数が負の場合は引く。
 * @param {DateInput} date
 * @param {number} months
 * @returns {Date | undefined}
 */
export const addMonths = (date, months) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    d.setMonth(d.getMonth() + months);
    return d;
};

/**
 * 日付に年数を足した日時を返す。年数が負の場合は引く。
 * @param {DateInput} date
 * @param {number} years
 * @returns {Date | undefined}
 */
export const addYears = (date, years) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    d.setFullYear(d.getFullYear() + years);
    return d;
};
