import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c7d9b05a = () => interopDefault(import('../website/pages/accounting-books/index.vue' /* webpackChunkName: "pages/accounting-books/index" */))
const _aeb7b674 = () => interopDefault(import('../website/pages/accounting-rule.vue' /* webpackChunkName: "pages/accounting-rule" */))
const _03313a51 = () => interopDefault(import('../website/pages/activate.vue' /* webpackChunkName: "pages/activate" */))
const _0bbe1cc0 = () => interopDefault(import('../website/pages/awa/index.vue' /* webpackChunkName: "pages/awa/index" */))
const _82d8be04 = () => interopDefault(import('../website/pages/beginning-balance.vue' /* webpackChunkName: "pages/beginning-balance" */))
const _75ec2801 = () => interopDefault(import('../website/pages/carryover.vue' /* webpackChunkName: "pages/carryover" */))
const _8efcadc2 = () => interopDefault(import('../website/pages/change-card.vue' /* webpackChunkName: "pages/change-card" */))
const _8f59c6ae = () => interopDefault(import('../website/pages/coincheck.vue' /* webpackChunkName: "pages/coincheck" */))
const _3cb511d8 = () => interopDefault(import('../website/pages/coins.vue' /* webpackChunkName: "pages/coins" */))
const _09cad63a = () => interopDefault(import('../website/pages/corporate/index.vue' /* webpackChunkName: "pages/corporate/index" */))
const _5f10660a = () => interopDefault(import('../website/pages/cpta/index.vue' /* webpackChunkName: "pages/cpta/index" */))
const _2a7fd716 = () => interopDefault(import('../website/pages/daily-rate/index.vue' /* webpackChunkName: "pages/daily-rate/index" */))
const _7d6571b8 = () => interopDefault(import('../website/pages/email-confirmation.vue' /* webpackChunkName: "pages/email-confirmation" */))
const _031e3732 = () => interopDefault(import('../website/pages/guardian/index.vue' /* webpackChunkName: "pages/guardian/index" */))
const _649ea1c6 = () => interopDefault(import('../website/pages/import/index.vue' /* webpackChunkName: "pages/import/index" */))
const _6d5a1e82 = () => interopDefault(import('../website/pages/inspectation.vue' /* webpackChunkName: "pages/inspectation" */))
const _579a950c = () => interopDefault(import('../website/pages/journal/index.vue' /* webpackChunkName: "pages/journal/index" */))
const _eba213ce = () => interopDefault(import('../website/pages/journal-master/index.vue' /* webpackChunkName: "pages/journal-master/index" */))
const _e577d99e = () => interopDefault(import('../website/pages/news.vue' /* webpackChunkName: "pages/news" */))
const _b4f8173a = () => interopDefault(import('../website/pages/pending-trades.vue' /* webpackChunkName: "pages/pending-trades" */))
const _309264a6 = () => interopDefault(import('../website/pages/pending-trades/index.vue' /* webpackChunkName: "pages/pending-trades/index" */))
const _b284bb12 = () => interopDefault(import('../website/pages/pending-trades/accounting-transfer.vue' /* webpackChunkName: "pages/pending-trades/accounting-transfer" */))
const _14ab0943 = () => interopDefault(import('../website/pages/pending-trades/defis.vue' /* webpackChunkName: "pages/pending-trades/defis" */))
const _78bdd89c = () => interopDefault(import('../website/pages/pending-trades/tx-confirmation.vue' /* webpackChunkName: "pages/pending-trades/tx-confirmation" */))
const _49180f4c = () => interopDefault(import('../website/pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _5bf1cd1a = () => interopDefault(import('../website/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _324b5209 = () => interopDefault(import('../website/pages/register-kanjobugyo.vue' /* webpackChunkName: "pages/register-kanjobugyo" */))
const _26542408 = () => interopDefault(import('../website/pages/regulation.vue' /* webpackChunkName: "pages/regulation" */))
const _488c4e50 = () => interopDefault(import('../website/pages/reminder.vue' /* webpackChunkName: "pages/reminder" */))
const _01b7ae1c = () => interopDefault(import('../website/pages/resetpass.vue' /* webpackChunkName: "pages/resetpass" */))
const _1ed57694 = () => interopDefault(import('../website/pages/sct.vue' /* webpackChunkName: "pages/sct" */))
const _40983f00 = () => interopDefault(import('../website/pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _552e9576 = () => interopDefault(import('../website/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _358b2bf0 = () => interopDefault(import('../website/pages/subscriptions.vue' /* webpackChunkName: "pages/subscriptions" */))
const _bd7d6aea = () => interopDefault(import('../website/pages/subscriptions/index.vue' /* webpackChunkName: "pages/subscriptions/index" */))
const _55aaf727 = () => interopDefault(import('../website/pages/subscriptions/apply.vue' /* webpackChunkName: "pages/subscriptions/apply" */))
const _40cf5fb1 = () => interopDefault(import('../website/pages/subscriptions/auto-renewal.vue' /* webpackChunkName: "pages/subscriptions/auto-renewal" */))
const _64dc1fde = () => interopDefault(import('../website/pages/subscriptions/cancel.vue' /* webpackChunkName: "pages/subscriptions/cancel" */))
const _3fa53b84 = () => interopDefault(import('../website/pages/subscriptions/payments.vue' /* webpackChunkName: "pages/subscriptions/payments" */))
const _8e06bd5a = () => interopDefault(import('../website/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _557fa9f6 = () => interopDefault(import('../website/pages/trade-download-history.vue' /* webpackChunkName: "pages/trade-download-history" */))
const _100d7bcf = () => interopDefault(import('../website/pages/trade-summary.vue' /* webpackChunkName: "pages/trade-summary" */))
const _37116726 = () => interopDefault(import('../website/pages/trades.vue' /* webpackChunkName: "pages/trades" */))
const _1a881f70 = () => interopDefault(import('../website/pages/trades-setting.vue' /* webpackChunkName: "pages/trades-setting" */))
const _a7580a3a = () => interopDefault(import('../website/pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _492d04af = () => interopDefault(import('../website/pages/user-setting/index.vue' /* webpackChunkName: "pages/user-setting/index" */))
const _20ceaefa = () => interopDefault(import('../website/pages/accounting-books/journal-list.vue' /* webpackChunkName: "pages/accounting-books/journal-list" */))
const _1ef8d227 = () => interopDefault(import('../website/pages/awa/contact-sent.vue' /* webpackChunkName: "pages/awa/contact-sent" */))
const _74ccbdb5 = () => interopDefault(import('../website/pages/awa/inquiry.vue' /* webpackChunkName: "pages/awa/inquiry" */))
const _1202a2e4 = () => interopDefault(import('../website/pages/corporate/change-plan.vue' /* webpackChunkName: "pages/corporate/change-plan" */))
const _3192c440 = () => interopDefault(import('../website/pages/corporate/signup.vue' /* webpackChunkName: "pages/corporate/signup" */))
const _9cf17d90 = () => interopDefault(import('../website/pages/cpta/cpta-office.vue' /* webpackChunkName: "pages/cpta/cpta-office" */))
const _2279b2fe = () => interopDefault(import('../website/pages/cpta/customers/index.vue' /* webpackChunkName: "pages/cpta/customers/index" */))
const _13af0182 = () => interopDefault(import('../website/pages/cpta/signup.vue' /* webpackChunkName: "pages/cpta/signup" */))
const _59362d39 = () => interopDefault(import('../website/pages/creator/signup.vue' /* webpackChunkName: "pages/creator/signup" */))
const _45e8617a = () => interopDefault(import('../website/pages/guardian/regulation.vue' /* webpackChunkName: "pages/guardian/regulation" */))
const _3f7e7a11 = () => interopDefault(import('../website/pages/import/api/index.vue' /* webpackChunkName: "pages/import/api/index" */))
const _1caaa190 = () => interopDefault(import('../website/pages/import/file.vue' /* webpackChunkName: "pages/import/file" */))
const _da56288a = () => interopDefault(import('../website/pages/import/file/index.vue' /* webpackChunkName: "pages/import/file/index" */))
const _0904a78a = () => interopDefault(import('../website/pages/import/file/blockchain.vue' /* webpackChunkName: "pages/import/file/blockchain" */))
const _c32e4b04 = () => interopDefault(import('../website/pages/import/file/blockchain/index.vue' /* webpackChunkName: "pages/import/file/blockchain/index" */))
const _446537a0 = () => interopDefault(import('../website/pages/import/file/blockchain/address.vue' /* webpackChunkName: "pages/import/file/blockchain/address" */))
const _3dda7f80 = () => interopDefault(import('../website/pages/import/file/blockchain/file.vue' /* webpackChunkName: "pages/import/file/blockchain/file" */))
const _370b605c = () => interopDefault(import('../website/pages/import/file/common.vue' /* webpackChunkName: "pages/import/file/common" */))
const _aa697cea = () => interopDefault(import('../website/pages/import/file/exchangers.vue' /* webpackChunkName: "pages/import/file/exchangers" */))
const _3524e63d = () => interopDefault(import('../website/pages/import/file/history.vue' /* webpackChunkName: "pages/import/file/history" */))
const _52090b00 = () => interopDefault(import('../website/pages/import/file/history/index.vue' /* webpackChunkName: "pages/import/file/history/index" */))
const _0e795fd4 = () => interopDefault(import('../website/pages/import/file/history/blockchain.vue' /* webpackChunkName: "pages/import/file/history/blockchain" */))
const _2835e3f8 = () => interopDefault(import('../website/pages/import/file/unimported.vue' /* webpackChunkName: "pages/import/file/unimported" */))
const _7f7e3522 = () => interopDefault(import('../website/pages/journal-master/accounts.vue' /* webpackChunkName: "pages/journal-master/accounts" */))
const _3b4ef169 = () => interopDefault(import('../website/pages/journal-master/addresses.vue' /* webpackChunkName: "pages/journal-master/addresses" */))
const _c048cee2 = () => interopDefault(import('../website/pages/journal-master/clients.vue' /* webpackChunkName: "pages/journal-master/clients" */))
const _91d35430 = () => interopDefault(import('../website/pages/journal-master/departments.vue' /* webpackChunkName: "pages/journal-master/departments" */))
const _3bbc8e07 = () => interopDefault(import('../website/pages/journal-master/items.vue' /* webpackChunkName: "pages/journal-master/items" */))
const _3eaa7bbe = () => interopDefault(import('../website/pages/journal-master/rules.vue' /* webpackChunkName: "pages/journal-master/rules" */))
const _00afb5ea = () => interopDefault(import('../website/pages/journal-master/tax-classes.vue' /* webpackChunkName: "pages/journal-master/tax-classes" */))
const _bc652f9c = () => interopDefault(import('../website/pages/journal-master/valuation-rules.vue' /* webpackChunkName: "pages/journal-master/valuation-rules" */))
const _6c39b3b4 = () => interopDefault(import('../website/pages/journal/beginning-balance.vue' /* webpackChunkName: "pages/journal/beginning-balance" */))
const _1388ff27 = () => interopDefault(import('../website/pages/journal/custom.vue' /* webpackChunkName: "pages/journal/custom" */))
const _2a403f1c = () => interopDefault(import('../website/pages/user-setting/change-email.vue' /* webpackChunkName: "pages/user-setting/change-email" */))
const _a9372536 = () => interopDefault(import('../website/pages/user-setting/change-mailmagazine.vue' /* webpackChunkName: "pages/user-setting/change-mailmagazine" */))
const _6c607296 = () => interopDefault(import('../website/pages/user-setting/change-password.vue' /* webpackChunkName: "pages/user-setting/change-password" */))
const _43913786 = () => interopDefault(import('../website/pages/user-setting/withdraw.vue' /* webpackChunkName: "pages/user-setting/withdraw" */))
const _71900064 = () => interopDefault(import('../website/pages/cpta/payment/apply.vue' /* webpackChunkName: "pages/cpta/payment/apply" */))
const _45a035ed = () => interopDefault(import('../website/pages/cpta/payment/change-card.vue' /* webpackChunkName: "pages/cpta/payment/change-card" */))
const _59b71623 = () => interopDefault(import('../website/pages/cpta/payment/finish-change-card.vue' /* webpackChunkName: "pages/cpta/payment/finish-change-card" */))
const _994a55e0 = () => interopDefault(import('../website/pages/cpta/payment/finish-register-card.vue' /* webpackChunkName: "pages/cpta/payment/finish-register-card" */))
const _d16e4338 = () => interopDefault(import('../website/pages/cpta/payment/finish-withdraw.vue' /* webpackChunkName: "pages/cpta/payment/finish-withdraw" */))
const _2dbba67e = () => interopDefault(import('../website/pages/cpta/payment/info.vue' /* webpackChunkName: "pages/cpta/payment/info" */))
const _497b2a5a = () => interopDefault(import('../website/pages/cpta/payment/register-card.vue' /* webpackChunkName: "pages/cpta/payment/register-card" */))
const _a2bbc596 = () => interopDefault(import('../website/pages/cpta/payment/unpaid.vue' /* webpackChunkName: "pages/cpta/payment/unpaid" */))
const _6eb23a4c = () => interopDefault(import('../website/pages/cpta/payment/withdraw.vue' /* webpackChunkName: "pages/cpta/payment/withdraw" */))
const _d193010e = () => interopDefault(import('../website/pages/cpta/payment/withdrawed.vue' /* webpackChunkName: "pages/cpta/payment/withdrawed" */))
const _e94a726e = () => interopDefault(import('../website/pages/awa/download/_docId.vue' /* webpackChunkName: "pages/awa/download/_docId" */))
const _7d1841fc = () => interopDefault(import('../website/pages/import/api/_exchangerId.vue' /* webpackChunkName: "pages/import/api/_exchangerId" */))
const _486ad838 = () => interopDefault(import('../website/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounting-books",
    component: _c7d9b05a,
    name: "accounting-books"
  }, {
    path: "/accounting-rule",
    component: _aeb7b674,
    name: "accounting-rule"
  }, {
    path: "/activate",
    component: _03313a51,
    name: "activate"
  }, {
    path: "/awa",
    component: _0bbe1cc0,
    name: "awa"
  }, {
    path: "/beginning-balance",
    component: _82d8be04,
    name: "beginning-balance"
  }, {
    path: "/carryover",
    component: _75ec2801,
    name: "carryover"
  }, {
    path: "/change-card",
    component: _8efcadc2,
    name: "change-card"
  }, {
    path: "/coincheck",
    component: _8f59c6ae,
    name: "coincheck"
  }, {
    path: "/coins",
    component: _3cb511d8,
    name: "coins"
  }, {
    path: "/corporate",
    component: _09cad63a,
    name: "corporate"
  }, {
    path: "/cpta",
    component: _5f10660a,
    name: "cpta"
  }, {
    path: "/daily-rate",
    component: _2a7fd716,
    name: "daily-rate"
  }, {
    path: "/email-confirmation",
    component: _7d6571b8,
    name: "email-confirmation"
  }, {
    path: "/guardian",
    component: _031e3732,
    name: "guardian"
  }, {
    path: "/import",
    component: _649ea1c6,
    name: "import"
  }, {
    path: "/inspectation",
    component: _6d5a1e82,
    name: "inspectation"
  }, {
    path: "/journal",
    component: _579a950c,
    name: "journal"
  }, {
    path: "/journal-master",
    component: _eba213ce,
    name: "journal-master"
  }, {
    path: "/news",
    component: _e577d99e,
    name: "news"
  }, {
    path: "/pending-trades",
    component: _b4f8173a,
    children: [{
      path: "",
      component: _309264a6,
      name: "pending-trades"
    }, {
      path: "accounting-transfer",
      component: _b284bb12,
      name: "pending-trades-accounting-transfer"
    }, {
      path: "defis",
      component: _14ab0943,
      name: "pending-trades-defis"
    }, {
      path: "tx-confirmation",
      component: _78bdd89c,
      name: "pending-trades-tx-confirmation"
    }]
  }, {
    path: "/portfolio",
    component: _49180f4c,
    name: "portfolio"
  }, {
    path: "/privacy",
    component: _5bf1cd1a,
    name: "privacy"
  }, {
    path: "/register-kanjobugyo",
    component: _324b5209,
    name: "register-kanjobugyo"
  }, {
    path: "/regulation",
    component: _26542408,
    name: "regulation"
  }, {
    path: "/reminder",
    component: _488c4e50,
    name: "reminder"
  }, {
    path: "/resetpass",
    component: _01b7ae1c,
    name: "resetpass"
  }, {
    path: "/sct",
    component: _1ed57694,
    name: "sct"
  }, {
    path: "/signin",
    component: _40983f00,
    name: "signin"
  }, {
    path: "/signup",
    component: _552e9576,
    name: "signup"
  }, {
    path: "/subscriptions",
    component: _358b2bf0,
    children: [{
      path: "",
      component: _bd7d6aea,
      name: "subscriptions"
    }, {
      path: "apply",
      component: _55aaf727,
      name: "subscriptions-apply"
    }, {
      path: "auto-renewal",
      component: _40cf5fb1,
      name: "subscriptions-auto-renewal"
    }, {
      path: "cancel",
      component: _64dc1fde,
      name: "subscriptions-cancel"
    }, {
      path: "payments",
      component: _3fa53b84,
      name: "subscriptions-payments"
    }]
  }, {
    path: "/thankyou",
    component: _8e06bd5a,
    name: "thankyou"
  }, {
    path: "/trade-download-history",
    component: _557fa9f6,
    name: "trade-download-history"
  }, {
    path: "/trade-summary",
    component: _100d7bcf,
    name: "trade-summary"
  }, {
    path: "/trades",
    component: _37116726,
    name: "trades"
  }, {
    path: "/trades-setting",
    component: _1a881f70,
    name: "trades-setting"
  }, {
    path: "/unsubscribe",
    component: _a7580a3a,
    name: "unsubscribe"
  }, {
    path: "/user-setting",
    component: _492d04af,
    name: "user-setting"
  }, {
    path: "/accounting-books/journal-list",
    component: _20ceaefa,
    name: "accounting-books-journal-list"
  }, {
    path: "/awa/contact-sent",
    component: _1ef8d227,
    name: "awa-contact-sent"
  }, {
    path: "/awa/inquiry",
    component: _74ccbdb5,
    name: "awa-inquiry"
  }, {
    path: "/corporate/change-plan",
    component: _1202a2e4,
    name: "corporate-change-plan"
  }, {
    path: "/corporate/signup",
    component: _3192c440,
    name: "corporate-signup"
  }, {
    path: "/cpta/cpta-office",
    component: _9cf17d90,
    name: "cpta-cpta-office"
  }, {
    path: "/cpta/customers",
    component: _2279b2fe,
    name: "cpta-customers"
  }, {
    path: "/cpta/signup",
    component: _13af0182,
    name: "cpta-signup"
  }, {
    path: "/creator/signup",
    component: _59362d39,
    name: "creator-signup"
  }, {
    path: "/guardian/regulation",
    component: _45e8617a,
    name: "guardian-regulation"
  }, {
    path: "/import/api",
    component: _3f7e7a11,
    name: "import-api"
  }, {
    path: "/import/file",
    component: _1caaa190,
    children: [{
      path: "",
      component: _da56288a,
      name: "import-file"
    }, {
      path: "blockchain",
      component: _0904a78a,
      children: [{
        path: "",
        component: _c32e4b04,
        name: "import-file-blockchain"
      }, {
        path: "address",
        component: _446537a0,
        name: "import-file-blockchain-address"
      }, {
        path: "file",
        component: _3dda7f80,
        name: "import-file-blockchain-file"
      }]
    }, {
      path: "common",
      component: _370b605c,
      name: "import-file-common"
    }, {
      path: "exchangers",
      component: _aa697cea,
      name: "import-file-exchangers"
    }, {
      path: "history",
      component: _3524e63d,
      children: [{
        path: "",
        component: _52090b00,
        name: "import-file-history"
      }, {
        path: "blockchain",
        component: _0e795fd4,
        name: "import-file-history-blockchain"
      }]
    }, {
      path: "unimported",
      component: _2835e3f8,
      name: "import-file-unimported"
    }]
  }, {
    path: "/journal-master/accounts",
    component: _7f7e3522,
    name: "journal-master-accounts"
  }, {
    path: "/journal-master/addresses",
    component: _3b4ef169,
    name: "journal-master-addresses"
  }, {
    path: "/journal-master/clients",
    component: _c048cee2,
    name: "journal-master-clients"
  }, {
    path: "/journal-master/departments",
    component: _91d35430,
    name: "journal-master-departments"
  }, {
    path: "/journal-master/items",
    component: _3bbc8e07,
    name: "journal-master-items"
  }, {
    path: "/journal-master/rules",
    component: _3eaa7bbe,
    name: "journal-master-rules"
  }, {
    path: "/journal-master/tax-classes",
    component: _00afb5ea,
    name: "journal-master-tax-classes"
  }, {
    path: "/journal-master/valuation-rules",
    component: _bc652f9c,
    name: "journal-master-valuation-rules"
  }, {
    path: "/journal/beginning-balance",
    component: _6c39b3b4,
    name: "journal-beginning-balance"
  }, {
    path: "/journal/custom",
    component: _1388ff27,
    name: "journal-custom"
  }, {
    path: "/user-setting/change-email",
    component: _2a403f1c,
    name: "user-setting-change-email"
  }, {
    path: "/user-setting/change-mailmagazine",
    component: _a9372536,
    name: "user-setting-change-mailmagazine"
  }, {
    path: "/user-setting/change-password",
    component: _6c607296,
    name: "user-setting-change-password"
  }, {
    path: "/user-setting/withdraw",
    component: _43913786,
    name: "user-setting-withdraw"
  }, {
    path: "/cpta/payment/apply",
    component: _71900064,
    name: "cpta-payment-apply"
  }, {
    path: "/cpta/payment/change-card",
    component: _45a035ed,
    name: "cpta-payment-change-card"
  }, {
    path: "/cpta/payment/finish-change-card",
    component: _59b71623,
    name: "cpta-payment-finish-change-card"
  }, {
    path: "/cpta/payment/finish-register-card",
    component: _994a55e0,
    name: "cpta-payment-finish-register-card"
  }, {
    path: "/cpta/payment/finish-withdraw",
    component: _d16e4338,
    name: "cpta-payment-finish-withdraw"
  }, {
    path: "/cpta/payment/info",
    component: _2dbba67e,
    name: "cpta-payment-info"
  }, {
    path: "/cpta/payment/register-card",
    component: _497b2a5a,
    name: "cpta-payment-register-card"
  }, {
    path: "/cpta/payment/unpaid",
    component: _a2bbc596,
    name: "cpta-payment-unpaid"
  }, {
    path: "/cpta/payment/withdraw",
    component: _6eb23a4c,
    name: "cpta-payment-withdraw"
  }, {
    path: "/cpta/payment/withdrawed",
    component: _d193010e,
    name: "cpta-payment-withdrawed"
  }, {
    path: "/awa/download/:docId?",
    component: _e94a726e,
    name: "awa-download-docId"
  }, {
    path: "/import/api/:exchangerId",
    component: _7d1841fc,
    name: "import-api-exchangerId"
  }, {
    path: "/",
    component: _486ad838,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
