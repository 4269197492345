<template>
    <div class="view">
        <GlobalHeader :show-customer="!cptaWithNoCustomerSelection" :showPeriod="!!getYear" class="header" />
        <EnvBar class="env" />
        <nuxt class="page" />
        <GlobalFooter class="footer" />
        <SideMenu v-if="!cptaWithNoCustomerSelection" class="side-menu" />

        <SPNotice />
        <UpgradeRequired />
        <ProhibitedTooltip v-if="isRestrictedUser" />

        <!-- zendeskウィジェット -->
        <script
            v-if="getIsRegularUser"
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=f59ee0c4-962a-4a29-9bf4-d98fdd2cf453"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GlobalHeader from '@/components/layout/GlobalHeader.vue';
import GlobalFooter from '@/components/layout/GlobalFooter.vue';
import MessageHandler from '@/mixins/MessageHandler.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import EnvBar from '@/components/layout/EnvBar.vue';
import SPNotice from '@/components/layout/SPNotice.vue';
import UpgradeRequired from '@/components/pages/subscriptions/UpgradeRequired.vue';
import ProhibitedTooltip from '@/components/ProhibitedTooltip.vue';

export default {
    components: {
        GlobalHeader,
        GlobalFooter,
        SideMenu,
        EnvBar,
        SPNotice,
        UpgradeRequired,
        ProhibitedTooltip,
    },
    mixins: [MessageHandler],
    middleware: 'authOnly',
    head() {
        if (!this.getIsDapps) return;
        return {
            title: 'Web3事業者向け経理サポートツール「Aerial Web3 Accounting」 | 暗号資産やNFTの会計処理をおまかせ!',
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content:
                        'Web3領域では避けられない仮想通貨(暗号資産)やNFTの会計処理にかかる作業時間を削減できる経理用ビジネスツール「Aerial Web3 Accounting(AWA)」は、ブロックチェーンの仕組みを知らなくても簡単に使えます。',
                },
            ],
            link: [{ rel: 'icon', hid: 'favicon-32', type: 'image/png', sizes: '32*32', href: '/awa-favicon-32.png' }],
        };
    },
    computed: {
        ...mapGetters(['getYear', 'getIsRegularUser', 'getIsCpta', 'getIsDapps', 'isInitialized', 'isRestrictedUser']),
        cptaWithNoCustomerSelection() {
            if (!this.getIsCpta) return false;
            return !this.isInitialized;
        },
    },
    mounted() {
        const { cssText } = document.getElementsByTagName('html')[0].style;
        document.getElementsByTagName('html')[0].style.cssText = `${cssText}font-size: 10px !important;`;
    },
};
</script>

<style scoped>
.view {
    min-height: 100vh;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto minmax(0, 1fr) auto;
    grid-template-areas:
        'header header'
        'env env'
        'side-menu page'
        'footer footer';
}
.env {
    grid-area: env;
}
.header {
    grid-area: header;
}
.page {
    grid-area: page;
}
.footer {
    grid-area: footer;
}
.side-menu {
    grid-area: side-menu;
}
</style>

<style lang="scss">
body {
    background-color: #fff;
    color: #444;
}

// breakpoints
$sp: 600;
$tb: 768;
$pc: 1440;

// media queries
$breakpoints: (
    'sp': 'screen and (max-width: #{$sp}px)',
    'tb': 'screen and (max-width: #{$tb}px)',
    'pc': 'screen and (max-width: #{$pc}px)',
) !default;

// メディアクエリ設定用 mix-in
@mixin mq($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

.dashboad {
    padding-bottom: 9rem;
    @include mq() {
        padding-bottom: 4.7rem;
    }
}
.table {
    @include mq() {
        overflow-x: scroll;
        > img {
            // ここはライブラリで実装時に調整してください
            width: 940px;
        }
    }
}
.btn-download-wrap {
    width: 280px;
    margin-left: auto;
    margin-right: 0;
    @include mq() {
        margin: 0 auto;
    }
}
.graph {
    &:not(:first-child) {
        margin-top: 6rem;
    }
    .graph__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .graph__header-text {
            margin: 0 2.5rem 0 0;
            font-size: 2rem;
            font-weight: bold;
            @include mq() {
                font-size: 1.6rem;
            }
        }
    }
    .graph__body {
        margin-top: 4.5rem;
        @include mq() {
            overflow-x: scroll;
            > img {
                // ここはライブラリで実装時に調整してください
                width: 940px;
            }
        }
    }
}
.piechart-container {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    @include mq() {
        display: block;
    }
    .piechart {
        display: flex;
        flex-direction: column;
        text-align: center;
        flex: 1 1 0px;
        &:not(:first-child) {
            @include mq() {
                margin-top: 5rem;
            }
        }
        .piechart__title {
            font-size: 2rem;
            font-weight: bold;
            color: #333333;
        }
        .piechart__body {
            font-size: 1rem;
            flex: 1 1 0px;
        }
    }
}
</style>
