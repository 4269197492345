<template>
    <el-dialog
        ref="dialog"
        :visible="visible"
        :width="width"
        :top="top"
        :close-on-click-modal="closeOnClickOutside"
        :append-to-body="appendToBody"
        :fullscreen="fullscreen"
        destroy-on-close
        :before-close="onCancel"
        @close="$emit('close')"
    >
        <template #title>
            <slot name="title">
                <slot name="pre-title" />
                <h2 class="title">{{ title }}</h2>
                <slot name="post-title" />
            </slot>
        </template>

        <slot />

        <footer>
            <p class="message">{{ message }}</p>
            <div class="action-bar">
                <slot name="pre-action" />

                <el-button v-if="cancelBtn" class="cancel-btn" @click="handleClose">{{ cancelText }}</el-button>
                <SubmitBtn
                    v-if="submitBtn"
                    :color="submitBtnTheme"
                    :disabled="!submitCondition"
                    @click="$emit('submit')"
                    >{{ submitText }}</SubmitBtn
                >

                <slot name="post-action" />
            </div>
        </footer>

        <SeeThruCover :trigger="loading" />
    </el-dialog>
</template>

<script>
import SubmitBtn from '@/components/SubmitBtn.vue';
import SeeThruCover from '@/components/SeeThruCover.vue';

export default {
    components: { SubmitBtn, SeeThruCover },
    props: {
        visible: { type: Boolean, required: true },
        title: { type: String, default: '' },
        width: { type: String, default: '900px' },
        submitBtn: { type: Boolean, default: true },
        submitText: { type: String, default: '登録する' },
        submitBtnTheme: { type: String, default: 'primary' },
        submitCondition: { type: Boolean, default: true },
        cancelBtn: { type: Boolean, default: true },
        cancelText: { type: String, default: 'キャンセル' },
        onCancel: { type: Function, default: undefined },
        top: { type: String, default: undefined },
        fullscreen: { type: Boolean },
        closeOnClickOutside: { type: Boolean, default: true },
        appendToBody: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        message: { type: String, default: '' },
    },
    methods: {
        handleClose() {
            this.$refs.dialog.handleClose();
        },
    },
};
</script>

<style lang="scss" scoped>
$text-clr: #444;

.title {
    display: inline-block;
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
    color: $text-clr;
}

footer {
    padding: 0 2.4rem;
    text-align: center;
    .message {
        margin: 0;
        height: 3rem;
        padding: 6px 0;
        color: #f46161;
        font-weight: bold;
    }

    .action-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.7rem;

        .cancel-btn {
            width: 17rem;
            padding: 1.6rem;
            font-size: 1.5rem;
            color: $text-clr;
        }
    }
}

::v-deep {
    .el-dialog {
        min-height: 300px;
        border-radius: 3px;
        overflow: hidden;
    }
    .el-dialog__header {
        position: relative;
        padding: 2.4rem;
        padding-right: 3.6rem;

        .el-dialog__headerbtn {
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }
        .el-dialog__close {
            font-size: 1.8rem;
            font-weight: bold;
            color: $text-clr;
        }
    }

    .el-dialog__body {
        padding: 28px 0;
    }
}
</style>
