<template>
    <footer class="lp-footer">
        <nav>
            <nuxt-link class="link" to="/regulation">利用規約</nuxt-link>
            <nuxt-link class="link" to="/privacy">プライバシーポリシー</nuxt-link>
            <nuxt-link class="link" to="/sct">特商法に基づく表示</nuxt-link>
            <a class="link" href="https://www.aerial-p.com" target="_blank">会社概要</a>
            <a class="link" href="https://support.crypto-city.net/hc/ja" target="_blank">サポートセンター（FAQ）</a>
            <a class="link" href="https://crypto-city.net/media" target="_blank">メディア</a>
            <br />
            <a class="link" href="https://crypto-city.net/corporate" target="_blank">Gtax（一般法人・個人事業主版）</a>
            <a class="link" href="https://crypto-city.net/awa" target="_blank">Web3事業者向け経理サポート「AWA」</a>
            <a class="link" href="https://www.aerial-p.com/service#adm" target="_blank"
                >金融事業者向け会計・データ管理「ADM」</a
            >
        </nav>

        <p>
            <slot name="copyRight" :currentYear="currentYear">Gtax &copy; 2018 - {{ currentYear }}</slot>
        </p>

        <slot />
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'LPFooter',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapGetters(['getIsCreator']),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/personal/base.scss';

.lp-footer {
    padding: 3em 0 0;
    text-align: center;

    @include sp {
        padding-top: 2em;
    }

    nav {
        font-size: $pcf_xs;

        @include wide {
            font-size: $pcmf_xs;
        }

        @include sp {
            font-size: $splf_xs;
        }

        .link {
            display: inline-block;
            color: #444;
            text-decoration: none;
            padding: 1em;

            @include sp {
                display: block;
            }
        }
    }

    p {
        margin: 0;
        padding: 3em 0;
        font-size: $pcf_xxs;

        @include wide {
            font-size: $pcmf_xxs;
        }

        @include tab {
            font-size: $pclf_xxs;
        }

        @include sp {
            font-size: $spf_xxs;
        }
    }
}
</style>
