// @ts-check
export * from './utils/dataStructure/list.js';
export * from './utils/dataStructure/sort.js';
export * from './utils/number/base.js';
export * from './utils/number/format.js';
export * from './utils/number/modification.js';
export * from './utils/string/modification.js';
export * from './validators/validationFunctions.js';

/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
export const wait = (ms) =>
    new Promise((resolve) => {
        window.setTimeout(resolve, ms);
    });
