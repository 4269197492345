<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 26" :class="theme">
        <path
            d="M13.645,0.768,0.211,23.697a1.536,1.536,0,0,0,1.35,2.3h26.877a1.536,1.536,0,0,0,1.35-2.3l-13.438-22.928A1.571,1.571,0,0,0,13.645,0.768Z"
        />
        <text x="15" y="21.5">!</text>
        <defs>
            <linearGradient id="primary-linear-gradient" x1="3" y1="15" x2="30" y2="15" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3AB0CD" />
                <stop offset="1" stop-color="#5571E5" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            default: 'danger', // 'primary' | 'warning' | 'danger'
        },
    },
};
</script>

<style scoped>
svg {
    vertical-align: bottom;
    width: 2rem;
}
.primary {
    fill: url(#primary-linear-gradient);
}
.warning {
    fill: #fcbe24;
}
.danger {
    fill: #e23434;
}
text {
    font-size: max(1.6rem, 40%);
    fill: #fff;
    font-weight: bold;
    text-anchor: middle;
}
</style>
