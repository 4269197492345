<template>
    <BaseDialog
        :visible="!!getUpgradeMessage"
        title="プランアップグレード"
        width="780px"
        submitText="料金プランへ"
        cancelText="戻る"
        @submit="submit"
        @close="close"
    >
        <div class="container">
            <h3>
                <WarningIcon theme="primary" style="width: 2.6rem" />
                <span>プランアップグレードのご案内</span>
            </h3>
            <div class="content" v-html="getUpgradeMessage" />
        </div>
    </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDialog from '@/components/BaseDialog.vue';
import WarningIcon from '@/components/icons/Warning.vue';

export default {
    components: {
        BaseDialog,
        WarningIcon,
    },
    computed: {
        ...mapGetters(['getUpgradeMessage']),
    },
    methods: {
        close() {
            this.$store.commit('setUpgradeMessage', undefined);
        },
        submit() {
            this.$router.push('/subscriptions');
            this.$store.commit('setUpgradeMessage', undefined);
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    margin: 0 auto;
    max-width: 41.6rem;
    padding: 24px 0 12px;
    line-height: 1.7;

    h3 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 2rem;
        font-weight: bold;
        color: #4694d8;
    }
    .content {
        margin-top: 32px;
        font-size: 1.5rem;

        ::v-deep p {
            margin: 0;
            & + p {
                margin-top: 1rem;
            }
        }
    }
}
</style>
