// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

import { toDate } from './base.js';

const TZ_TOKYO = 'Asia/Tokyo';

/**
 * 渡した日時を日本時間の日付のみの文字列(YYYY-MM-DD)で返す。
 * ２つ目の引数で日付の区切り文字を指定できる（デフォルトは'-')。
 * @param {DateInput} date
 * @param {string} [splitter='-']
 * @returns {string|undefined}
 */
export const formatDate = (date, splitter = '-') => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    /** @type {Intl.DateTimeFormatOptions} */
    const options = {
        timeZone: TZ_TOKYO,
        dateStyle: 'medium',
    };
    const str = d.toLocaleString('ja-JP', options);

    if (splitter === '/') return str;
    return str.replace(/\//g, splitter);
};

/**
 * 渡した日時を日本時間の時間のみの文字列(HH:mm:ss)で返す。
 * @param {DateInput} date
 * @returns {string|undefined}
 */
export const formatTime = (date) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    /** @type {Intl.DateTimeFormatOptions} */
    const options = {
        timeZone: TZ_TOKYO,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    return d.toLocaleString('ja-JP', options);
};

/**
 * 渡した日時を日本時間の文字列(YYYY-MM-DD HH:mm:ss)で返す。
 * ２つ目の引数で日付の区切り文字を指定できる（デフォルトは'-')。
 * @param {DateInput} date
 * @param {string} [splitter='-']
 * @returns {string|undefined}
 */
export const formatDateTime = (date, splitter = '-') => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    /** @type {Intl.DateTimeFormatOptions} */
    const options = {
        timeZone: TZ_TOKYO,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    const str = d.toLocaleString('ja-JP', options);

    if (splitter === '/') return str;
    return str.replace(/\//g, splitter);
};

/**
 * 渡した日時の日付を日本時間の日本語(YYYY年MM月DD日)の文字列で返す。
 * @param {DateInput} date
 * @returns {string|undefined}
 */
export const formatDateJP = (date) => {
    const d = toDate(date);
    if (d === undefined) return undefined;

    /** @type {Intl.DateTimeFormatOptions} */
    const options = {
        timeZone: TZ_TOKYO,

        dateStyle: 'long',
    };
    return d.toLocaleString('ja-JP', options);
};
