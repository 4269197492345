// @ts-check
/** @typedef {import('./types').ServerServiceNotice} ServerServiceNotice */
/** @typedef {import('./types').ServiceNotice} ServiceNotice */

/**
 * @param {ServerServiceNotice} notice
 * @returns {ServiceNotice}
 */
export const deserializeServiceNotice = (notice) =>
    notice
        ? {
              id: notice.id,
              message: notice.message,
              type: notice.type,
              targetVersions: deseriarizeTargetVersion(notice.target_version),
          }
        : undefined;

/**
 * @param {ServerServiceNotice['target_version']} targetVersion
 * @returns {ServiceNotice['targetVersions']}
 */
const deseriarizeTargetVersion = (targetVersion) => targetVersion?.split(',').map((v) => v.trim()) ?? [];
