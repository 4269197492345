// @ts-check
/** @typedef {import('./types').DateInput} DateInput */

import { toDate } from './base.js';

/**
 * @param {DateInput} from
 * @param {DateInput} to
 * @returns {string | undefined}
 */
export const getFormattedDistance = (from, to = new Date()) => {
    const f = toDate(from);
    const t = toDate(to);

    if (f === undefined || t === undefined) return undefined;

    const { diff, suffix } = initComparator(f.getTime(), t.getTime());

    let lastVal = 1000;
    // eslint-disable-next-line no-restricted-syntax
    for (const { threshold, unit } of generateThreshold()) {
        if (diff < threshold) {
            const valForUnit = Math.round(diff / lastVal);
            return formatter[unit](valForUnit, suffix);
        }

        lastVal = threshold;
    }
};

function* generateThreshold() {
    let cur = 1000;

    yield { threshold: cur, unit: 'ms' }; // １秒
    yield { threshold: (cur *= 60), unit: 's' }; // １分
    yield { threshold: (cur *= 60), unit: 'm' }; // １時間
    yield { threshold: (cur *= 24), unit: 'h' }; // １日
    yield { threshold: (cur *= 31), unit: 'd' }; // １か月
    yield { threshold: 31536000000, unit: 'M' }; // １年
    yield { threshold: Number.MAX_VALUE, unit: 'y' };
}

/**
 * @type {Record<string, (val: number, suffix: string) => string>}
 */
const formatter = {
    ms: () => 'たった今',
    s: (val, suffix) => `${val}秒${suffix}`,
    m: (val, suffix) => `${val}分${suffix}`,
    h: (val, suffix) => `${val}時間${suffix}`,
    d: (val, suffix) => `${val}日${suffix}`,
    M: (val, suffix) => `${val}か月${suffix}`,
    y: (val, suffix) => `${val}年${suffix}`,
};

/**
 * @param {number} from
 * @param {number} to
 * @returns {{ diff: number; suffix: string; }}
 */
const initComparator = (from, to) => {
    const isFromBeforeTo = from < to;

    return isFromBeforeTo
        ? {
              diff: to - from,
              suffix: '前',
          }
        : {
              diff: from - to,
              suffix: '後',
          };
};
