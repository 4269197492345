import * as Sentry from '@sentry/vue';

/**
 * Sentryにメッセージを送ります。
 * @param {string} message
 * @param {Record<string, string>} tags
 */
export const notifySentry = (message, tags = {}) => {
    // eslint-disable-next-line no-console
    console.warn(message);

    Sentry.captureMessage(message, {
        tags: {
            type: 'notification',
            notificationTarget: 'dev',
            ...tags,
        },
    });
};

export const setSentryUser = ({ id, customer_name, email }) => {
    const userInfo = { id, email };
    if (customer_name) userInfo.username = customer_name;
    Sentry.setUser(userInfo);
};

export const clearSentryUser = () => {
    Sentry.setUser(null);
};
