<template>
    <footer class="global-footer">
        <ul class="footer__link">
            <li>
                <nuxt-link class="footer__item" to="/coins">対応通貨リスト</nuxt-link>
            </li>
            <li>
                <nuxt-link class="footer__item" to="/regulation">利用規約</nuxt-link>
            </li>
            <li>
                <nuxt-link class="footer__item" to="/privacy">プライバシーポリシー</nuxt-link>
            </li>
            <li>
                <nuxt-link class="footer__item" to="/sct">特商法に基づく表示</nuxt-link>
            </li>
            <li>
                <a class="footer__item" href="https://www.aerial-p.com/about" target="_blank">会社情報</a>
            </li>
            <li>
                <a
                    v-if="getIsCreator"
                    class="footer__item"
                    href="https://ruddy-delivery-23e.notion.site/Gtax-for-Creators-4d356af82d104090b75f8e05c0c18da5"
                    target="_blank"
                    >お問い合わせ</a
                >
                <a v-else class="footer__item" href="https://support.crypto-city.net/hc/ja" target="_blank"
                    >お問い合わせ</a
                >
            </li>
        </ul>

        <small class="footer__copyright">{{ serviceName }} &copy; 2018 - {{ currentYear }}</small>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'GlobalFooter',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapGetters(['getIsCreator', 'serviceName']),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/foundation/_mixin.scss';

.global-footer {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        '_ list'
        '_ copyright';
    row-gap: 1.8em;
    padding: 2.4rem;
    background-color: #f8f8f8;
    line-height: 1.6;

    @media (max-width: 960px) {
        grid-template-columns: 60px 1fr;
    }

    .footer__link {
        grid-area: list;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        column-gap: 2.8rem;
        row-gap: 1em;

        @include mq(tb) {
            display: grid;
            grid-template-columns: repeat(auto-fit, 10em);
            justify-content: space-between;
            font-size: 1.3rem;
            gap: 1em;
        }

        > li {
            list-style: none;
            .footer__item {
                color: #444;
                white-space: nowrap;
            }
        }
    }
    .footer__copyright {
        grid-area: copyright;
        color: #444;
        @media (max-width: 960px) {
            font-size: 1rem;
        }
    }
}
</style>
