<template>
    <div :class="{ on }">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        on: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.on {
    position: relative;

    &:after {
        content: '';
        height: 7px;
        width: 7px;
        position: absolute;
        top: -5px;
        right: -8px;
        border-radius: 50%;
        background-color: #f77171;
    }
}
</style>
