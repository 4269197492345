// @ts-check
import { SOFTWARE } from '@/libs/const.js';
import { getFormattedPeriodRange, getPeriodRange, isBefore, toDate, differenceInDays } from '@/libs/date.js';
import { get } from '@/libs/vuex.js';

export default {
    vw: get('vw'),
    isWideScreen: (state) => {
        return state.vw >= 769;
    },
    isTabletScreen: (state) => {
        return state.vw >= 601 && state.vw <= 768;
    },
    isSpScreen: (state) => {
        return state.vw <= 600;
    },
    isLoggedIn: get('hasToken'),
    isInitialized: get('isInitialized'),
    getCustomerUserId: get('customerUserId'),
    getCurrentUser: get('currentUser'),
    serviceName: get('serviceName'),
    getMailMagazineFlag: (state) => {
        return state.currentUser.mailmagazine_flag;
    },
    /** TODO: ココからリファクター */
    getIsRegularUser: (state) => {
        return (
            state.currentUser.cookie_params.account_type !== 'cpta' &&
            state.currentUser.cookie_params.account_type !== 'corporate' &&
            state.currentUser.cookie_params.account_type !== 'creator' &&
            state.currentUser.cookie_params.account_type !== 'customer' &&
            state.currentUser.cookie_params.account_type !== 'cpta_corporate'
        );
    },
    getIsCpta: (state) => {
        return state.currentUser.cookie_params.account_type === 'cpta';
    },
    getIsCustomer: (state) => {
        return state.currentUser.cookie_params.account_type === 'customer';
    },
    getIsCptaCorporate: (state) => {
        return state.currentUser.cookie_params.account_type === 'cpta_corporate';
    },
    getIsCorporate: (state) => {
        return state.currentUser.cookie_params.account_type === 'corporate';
    },
    getIsCreator: (state) => {
        return state.currentUser.cookie_params.account_type === 'creator';
    },
    getIsEnterprise: (state) => {
        return ['corporate_enterprise', 'corporate_enterprise_dapps', 'corporate_enterprise_exchanger'].includes(
            state.currentUser.cookie_params.plan
        );
    },
    getIsDapps: (state) => {
        return state.currentUser.cookie_params.plan === 'corporate_enterprise_dapps';
    },
    getIsBusiness: (state) => {
        return (
            state.currentUser.cookie_params.account_type === 'corporate' ||
            state.currentUser.cookie_params.account_type === 'cpta'
        );
    },
    /** TODO: ココまで */
    getAccountType: (state) => {
        return state.currentUser.cookie_params.account_type;
    },
    getPlan: (state) => {
        return state.currentUser.cookie_params.plan;
    },
    getUpgradeMessage: get('upgradeMessage'),
    getCptaStatus: (state) => {
        return state.currentUser.cookie_params.cpta_status;
    },
    getCptaOfficeStatus: (state) => {
        return state.currentUser.cookie_params.cpta_office_status;
    },
    getIsGuardian: (state) => {
        return state.currentUser.cookie_params.cpta_office_status.is_guardian;
    },
    getTutorialState: get('tutorials'),
    getYear: (state) => {
        return state.period.current.year;
    },
    getAddress: (state) => {
        return state.currentUser.cookie_params.address;
    },
    getAccountingMonth: (state) => {
        return state.period.accountingMonth;
    },
    getClosingTerm: (state) => {
        return state.period.closingTerm;
    },
    getIsCloseValuate: (state) => {
        return state.period.isCloseValuate;
    },
    getOldestYear: (state) => {
        return state.period.oldest;
    },
    getBeginningBalanceYear: (state) => {
        return state.period.beginningBalanceYear;
    },
    getOldestOpenYear: (state) => {
        return state.period.oldestOpen;
    },
    getLimitOldestYear: (state) => {
        return state.period.limitOldest;
    },
    getLatestYear: (state) => {
        return state.period.latest;
    },
    getLatestClosedTerm: get('latestClosedTerm'),
    getMaxReachableYear: (state) => {
        return state.period.max_reachable_year;
    },
    getClosingStatus: (state) => {
        return state.period.current.closingStatus;
    },
    getPeriodRange: (state) => {
        if (!state.period.current.year || !state.period.accountingMonth) return {};
        return getPeriodRange(state.period.current.year, state.period.accountingMonth);
    },
    getFormattedPeriodRange: (state) => {
        if (!state.period.current.year || !state.period.accountingMonth) return {};
        return getFormattedPeriodRange(state.period.current.year, state.period.accountingMonth);
    },
    getUserCalcType(state) {
        return state.currentUser.calc_type;
    },
    getCalcState: get('calcState'),
    getCalcStatus: (state) => {
        return state.calcState.status;
    },
    getOfficeWord(_, getters) {
        return getters.getIsCorporate ? '会社' : '事務所';
    },
    getZeroTradeRecord: get('zeroTradeRecord'),
    getOrderTypes: get('orderTypes'),
    getExchangers: get('exchangers'),
    getUnimportedTradeCount: get('unimportedTradeCount'),
    getPendingTradeCount: get('pendingTradeCount'),
    getPendingDefiCount: get('pendingDefiCount'),
    getAccountingTransferCount: get('accountingTransferCount'),
    getTxConfirmationCount: get('txConfirmationCount'),
    getErrorMessage: get('errorMessage'),
    // 選択年度以前のマイナスがあるかどうか
    getMinusBalance: (state, getters) => {
        return state.minusBalance.filter(
            (mb) =>
                isBefore(mb.first_minus_at, getters.getPeriodRange.to) ||
                differenceInDays(mb.first_minus_at, getters.getPeriodRange.to) === 0
        );
    },
    isCheckCalcStateRunning: get('isCheckCalcStateRunning'),
    // cpta法人含むisCorporate
    getIsCorporateDisplay: (state) => {
        return state.currentUser.is_corporate_display;
    },
    getIsSetAccountingRule: (state) => {
        return state.currentUser.is_set_accounting_rule;
    },
    getAccountingSoftwareId: (state) => state.accountingSoftware?.id ?? '',
    getAccountingSoftwareName: (state) => state.accountingSoftware?.name ?? '未設定',
    isUsingFreee: (state) => state.accountingSoftware?.id === SOFTWARE.freee,
    getRegistrationApplied: (state) => {
        return state.currentUser.cookie_params.registration_applied;
    },
    getPeriodLoaded: (state) => {
        return state.period.loaded;
    },
    getHomeURL: (_, getters) => {
        if (!getters.isLoggedIn) return '/';
        if (getters.getIsCpta) return '/cpta/customers';
        return getters.isRestrictedUser ? '/portfolio' : '/import/file/exchangers';
    },
    getOrderType: (state) => (id) => {
        return state.orderTypes.find((oT) => oT.id === id);
    },
    getExchanger: (state) => (id) => {
        return state.exchangers.find((ex) => ex.id === id);
    },
    getFileAlert: (state) => (exchangerId, filetypeId) => {
        const alert = state.fileAlerts.find(
            (a) => a.exchanger_id === exchangerId && (a.is_all_filetype || a.filetype === filetypeId)
        );
        return alert?.alert;
    },
    getExchangerUsage: get('exchangerUsage'),
    getJournalSchema: (state) => (orderType) => state.journalSchemaMap[orderType],
    getJournalRuleSchema: (state) => (orderType) => state.journalRuleSchemaMap[orderType],
    getServiceNotice: get('serviceNotice'),
    getNews: get('news'),
    getLatestNewsDate: (state) => state.news[0]?.pubDate,
    getLastViewedNewsDate: get('lastViewedNewsDate'),
    hasUnreadNews: (_, getters) => {
        const latestNews = toDate(getters.getLatestNewsDate);
        const lastViewed = toDate(getters.getLastViewedNewsDate);

        if (latestNews === undefined || lastViewed === undefined) return true;
        return lastViewed < latestNews;
    },
    // 個人版を無料で使える年度。（例：2021年度以前は無料）
    // 法人版の場合は料金体系が違うので関係ない
    getFreeYearThreshold: () => {
        // 3/31までは現在の年度より3年以上前が無料
        // 4/1以降は現在の年度より2年以上前が無料
        const now = new Date();
        const threshold = new Date(now);
        threshold.setMonth(3);
        threshold.setDate(1);
        return now.getTime() < threshold.getTime() ? now.getFullYear() - 3 : now.getFullYear() - 2;
    },
    getCampaignInfo: get('campaign'),
    tradeValidator: get('tradeValidator'),
    // 新プラン移行後フリープランのままになっている既存ユーザーには利用制限がかかる
    // https://gtaxent.backlog.com/view/GTAXTOC-1580
    isRestrictedUser: (state) => state.currentUser.is_free_plan_limit,
};
