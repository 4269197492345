// @ts-check

/**
 * @param {string} str
 * @param {number} [threshold = 6] 文字列がこの値以下のときは何もしない。規定値は6。
 * @returns {string} 例: truncateMid('0123456789') -> '012...789'
 */
export const truncateMid = (str, threshold = 6) => {
    if (threshold < 3) throw new Error('thresholdは3以上で指定してください。');

    if (str.length <= threshold) return str;

    const surfaceLen = Math.floor(threshold / 2);
    return `${str.slice(0, surfaceLen)}...${str.slice(surfaceLen * -1)}`;
};

/**
 * NFT通貨（"currency(token)"）のtoken部分を省略する。
 * @param {string} currencyToken
 * @returns {string} 例: abbreviateNftCurrency('SOL(1234567890)') -> 'SOL(123...890)'
 */
export const abbreviateNftCurrency = (currencyToken) => {
    const token = currencyToken.substring(currencyToken.indexOf('(') + 1, currencyToken.indexOf(')'));
    const currency = currencyToken.split('(')[0];
    return `${currency}(${truncateMid(token)})`;
};

/**
 * @param {string} snake_case
 * @returns {string} camelCase
 */
export const snakeToCamel = (snake_case) =>
    snake_case.replace(/_./g, (match) => {
        const secondChar = match.charAt(1);
        return secondChar.match(/[a-z0-9]/) ? secondChar.toUpperCase() : match;
    });

/**
 * @param {Record<string, any>} snake_case_obj
 * @param {(key?: string) => boolean} matcher
 * @returns {Record<string, any>} camelized_obj
 */
// eslint-disable-next-line camelcase
export const camelizeObjectKeys = (snake_case_obj, matcher = () => true) =>
    Object.entries(snake_case_obj).reduce((camelized, [key, val]) => {
        const newKey = matcher(key) ? snakeToCamel(key) : key;
        camelized[newKey] = val;
        return camelized;
    }, {});

/**
 * @param {string} camelCase
 * @returns {string} snake_case
 */
export const camelToSnake = (camelCase) => {
    if (camelCase.match(/^[A-Z_]/)) return camelCase;
    return camelCase.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
};

/**
 * @param {Record<string, any>} camelCaseObj
 * @param {(key?: string) => boolean} matcher
 * @returns {Record<string, any>} snakened_obj
 */
// eslint-disable-next-line camelcase
export const snakenObjectKeys = (camelCaseObj, matcher = () => true) =>
    Object.entries(camelCaseObj).reduce((snakened, [key, val]) => {
        const newKey = matcher(key) ? camelToSnake(key) : key;
        snakened[newKey] = val;
        return snakened;
    }, {});
