// @ts-check
/** @typedef {import('../../date/types').DateInput} DateInput */

/**
 * @param { { tradedAt: DateInput}[] } trades
 * @returns { { ascending: () => void, descending: () => void } }
 */
export const sortByTradedAt = (trades) => ({
    ascending: () => trades.sort((a, b) => new Date(a.tradedAt).getTime() - new Date(b.tradedAt).getTime()),
    descending: () => trades.sort((a, b) => new Date(b.tradedAt).getTime() - new Date(a.tradedAt).getTime()),
});
