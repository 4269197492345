// @ts-check
/** @typedef { typeof STORAGE_KEYS['accessToken'] } StorageConfig */

const LOCAL = window.localStorage;
const SESSION = window.sessionStorage;

export const STORAGE_KEYS = {
    accessToken: { key: 'access_token', storage: LOCAL },
    tutorials: { key: 'tutorials', storage: LOCAL },
    latestPubDateOfViewedNews: { key: 'viewed_news', storage: LOCAL },
    hiddenServiceNotice: { key: 'hid_notice', storage: LOCAL },
    isForceLogin: { key: 'is_force_login', storage: LOCAL },
    cptaSession: { key: 'cpta_session', storage: SESSION },
};

/** @type { StorageConfig[] } */
export const deprecatedKeys = [
    { key: 'campaign_pop_0106', storage: LOCAL },
    { key: 'is_coincheck_user', storage: LOCAL },
];
