// @ts-check
export const prices = {
    corporate: {
        minimum: {
            monthly: '2,980',
            yearly: '30,000',
            discount: '5,760',
        },
        basic: {
            monthly: '4,980',
            yearly: '49,800',
            discount: '9,760',
        },
        professional: {
            monthly: '9,980',
            yearly: '99,800',
            discount: '19,960',
        },
        corporate_basic: 4980,
        corporate_professional: 9980,
        corporate_minimum_2021: '55,000',
        corporate_basic_2021: '110,000',
        corporate_professional_2021: '220,000',
    },
};
