<template>
    <div class="container">
        <div v-if="getYear > getLimitOldestYear" class="button prev" @click="$store.dispatch('backYear')" />
        <div v-else-if="getYear > getOldestYear" class="button prev prev-disabled">
            <HelpText class="help">ご利用のプランでは、これ以上過去の履歴を見ることはできません。</HelpText>
        </div>

        <div class="year">
            <span>{{ getYear }}年度</span>

            <HelpText>
                「期間」は、<b>損益情報を取得する対象の期間</b>です。仮想通貨の損益計算は年単位で行われるため、選択された期間における損益情報が表示されます。
                <br /><br />
                確定済み年度へ取引追加・編集・削除が必要な場合のみ、<nuxt-link to="/carryover" class="text-link"
                    >こちら</nuxt-link
                >より解除してください
            </HelpText>
        </div>

        <nuxt-link to="/carryover" class="el-button close" :class="{ closed: isClosed }">{{
            isClosed ? '確定済' : '未確定'
        }}</nuxt-link>

        <div class="span-text">{{ periodString }}</div>

        <div v-if="getYear < getMaxReachableYear" class="button next" @click="$store.dispatch('advanceYear')" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFormattedPeriodRange } from '@/libs/date.js';
import HelpText from '@/components/HelpText.vue';

export default {
    name: 'Period',
    components: { HelpText },
    computed: {
        ...mapGetters([
            'getYear',
            'getAccountingMonth',
            'getClosingStatus',
            'getOldestYear',
            'getOldestOpenYear',
            'getLimitOldestYear',
            'getLatestYear',
            'getMaxReachableYear',
        ]),
        isClosed() {
            return this.getClosingStatus === 'closed';
        },
        periodString() {
            const { from, to } = getFormattedPeriodRange(this.getYear, this.getAccountingMonth, '.');
            return `${from.slice(2)}～${to.slice(2)}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    display: grid;
    grid-template-columns: 24px 0 minmax(min-content, 1fr) auto 0 24px;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        'prev gap1 year closed gap2 next'
        'prev gap1 sub closed gap2 next';
    align-items: center;
    column-gap: 16px;
    row-gap: 4px;
    line-height: 1;
}

.year {
    grid-area: year;
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: bold;
    font-size: 1.4rem;
    color: #2a87e3;
    letter-spacing: 0.5px;
}

.close {
    --clr-main: #2a87e3;
    --clr-bg: #eaf8fa;

    grid-area: closed;
    opacity: 1 !important;
    padding: 10px 15px;
    font-size: 1.2rem;
    font-weight: bold;

    color: var(--clr-main);
    background-color: var(--clr-bg);
    border-color: var(--clr-main);

    &:hover {
        color: var(--clr-bg);
        background-color: var(--clr-main);
        border-color: var(--clr-bg);
    }

    &.closed {
        --clr-main: #fff;
        --clr-bg: #30bace;
    }
}

.span-text {
    grid-area: sub;
    font-size: 1.2rem;
    color: #666;
    white-space: nowrap;
}
.button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;

    &.prev {
        grid-area: prev;
        background-image: url('~assets/images/design/icon_arrow-left.svg');

        &-disabled {
            cursor: default;
            background-image: url('~assets/images/design/icon_arrow-left-disable.svg');

            .help {
                position: relative;
                left: 65%;
                top: 1px;
            }
        }
    }
    &.next {
        grid-area: next;
        background-image: url('~assets/images/design/icon_arrow-right.svg');
    }
}

.text-link {
    text-decoration: underline;
}
</style>
