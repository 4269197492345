import { isRestrictedPath } from '@/libs/const/userRestriction.js';

export default async ({ app, store, route, redirect }) => {
    if (!store.getters.isLoggedIn) {
        try {
            await store.dispatch('authenticate', { app });
        } catch (_) {
            return redirect('/signin');
        }
    }

    if (store.getters.isRestrictedUser && isRestrictedPath(route.path)) {
        return redirect(store.getters.getHomeURL);
    }
    if (route.path.startsWith('/import/api') && !store.state.currentUser.feature_toggle?.api_key_register) {
        return redirect('/import/file/exchangers');
    }
    if (
        route.path.startsWith('/subscriptions') &&
        (!store.getters.getIsRegularUser || store.getters.getCurrentUser.is_free)
    ) {
        return redirect(store.getters.getHomeURL);
    }

    if (store.getters.getIsCpta) {
        if (route.path.startsWith('/cpta/customers') && store.getters.isInitialized) {
            return store.dispatch('initCptaUser');
        }
        if (isAccessibleWithoutUserId(route.path)) return;
        if (!store.getters.getCustomerUserId) {
            try {
                await store.dispatch('retrieveCustomerId');
            } catch (_) {
                return redirect('/cpta/customers');
            }
        }
    }

    if (!store.getters.isInitialized) {
        const { required } = await store.dispatch('initAppData');
        await required;
    }

    if (!store.getters.getIsBusiness) return;

    if (store.getters.getIsEnterprise) {
        if (route.path.indexOf('/cpta/payment') !== -1) {
            return redirect('/import/file/exchangers');
        }
    }

    if (store.getters.getCptaOfficeStatus.paid_status === 'trial_expired') {
        if (
            route.path !== '/cpta/payment/apply' &&
            route.path !== '/cpta/payment/register-card' &&
            route.path !== '/user-setting' &&
            route.path !== '/cpta/payment/withdraw'
        ) {
            return redirect('/cpta/payment/apply');
        }
    }

    if (store.getters.getCptaOfficeStatus.paid_status === 'unpaid') {
        if (
            ![
                '/cpta/payment/unpaid',
                '/cpta/payment/change-card',
                '/cpta/payment/finish-change-card',
                '/user-setting',
                '/cpta/payment/withdraw',
            ].includes(route.path)
        ) {
            return redirect('/cpta/payment/unpaid');
        }
        return;
    }

    if (store.getters.getCptaOfficeStatus.paid_status === 'withdrawed') {
        if (route.path !== '/cpta/payment/withdrawed') {
            return redirect('/cpta/payment/withdrawed');
        }
    }

    // 法人版で未支払い（お試し期間）かどうかによるアクセス制御
    if (store.getters.getCptaOfficeStatus.registration_applied) {
        if (['/cpta/payment/apply', '/cpta/payment/register-card'].includes(route.path)) {
            return redirect('/cpta/payment/info');
        }
    } else if (['/cpta/payment/info', '/cpta/payment/change-card'].includes(route.path)) {
        return redirect('/cpta/payment/apply');
    }
};

const isAccessibleWithoutUserId = (path) => path.startsWith('/cpta/') || path.startsWith('/user-setting');
