// @ts-check

/**
 * @param {string|number} _a
 * @param {string|number} _b
 * @returns {number}
 */
export const addFloatsSafely = (_a, _b) => {
    const factor = 10 ** 8;

    const a = typeof _a === 'string' ? Number.parseFloat(_a) : _a;
    const b = typeof _b === 'string' ? Number.parseFloat(_b) : _b;

    return Math.round(a * factor + b * factor) / factor;
};
