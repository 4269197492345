<template>
    <Transition name="fly">
        <div v-show="isVisible" :style="position" @mouseenter="keepHoverStatus">
            この機能を利用するには<nuxt-link
                style="text-decoration: underline"
                to="/subscriptions/payments"
                @click.native="cleanup"
                >有料プランへのアップグレード</nuxt-link
            >が必要です。
        </div>
    </Transition>
</template>

<script>
export default {
    data() {
        return {
            hovered: false,
            timeout: undefined,
            position: { top: 0, right: 0 },
        };
    },
    computed: {
        state() {
            return this.$store.state.prohibitedTooltip;
        },
        isVisible() {
            return this.state !== undefined;
        },
    },
    watch: {
        state(val) {
            if (!val) return;
            this.onMouseEnter(val.event);
        },
    },
    methods: {
        onMouseEnter(e) {
            if (this.timeout) clearTimeout(this.timeout);
            this.hovered = true;
            this.position = this.calculatePosition(e);
            e.target.addEventListener('mouseleave', this.onMouseLeave, { once: true });
        },
        onMouseLeave() {
            this.hovered = false;
            this.timeout = window.setTimeout(() => {
                if (this.hovered) return;
                this.cleanup();
            }, 250);
        },
        keepHoverStatus(e) {
            if (this.timeout) clearTimeout(this.timeout);
            this.hovered = true;
            e.target.addEventListener('mouseleave', this.onMouseLeave, { once: true });
        },
        calculatePosition(e) {
            const pos = e.target.getBoundingClientRect();
            const sideOffset = this.state.sideOffset ?? 0;
            if (this.state.position === 'left') {
                return {
                    '--top': `${pos.top}px`,
                    '--right': `${window.innerWidth - pos.left + sideOffset}px`,
                };
            }
            if (this.state.position === 'right') {
                return {
                    '--top': `${pos.top}px`,
                    '--left': `${pos.right + sideOffset}px`,
                };
            }
        },
        cleanup() {
            this.$store.commit('hideProhibitedTooltip');
        },
    },
};
</script>

<style lang="scss" scoped>
div {
    width: 27rem;
    max-width: 40svw;
    padding: 1.6rem 2rem;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 5px;
    line-height: 1.5;
    font-weight: normal;
    white-space: normal;
    cursor: default;

    position: fixed;
    z-index: 9999;
    top: 0;
    left: var(--left, 'unset');
    right: var(--right, 'unset');
    transform: translate(0, var(--top));
    transition: transform 200ms;
}

.fly-enter-active,
.fly-leave-active {
    transition: 200ms;
}
.fly-enter,
.fly-leave-to {
    opacity: 0;
    transform: translate(-6px, var(--top));
}
</style>
