export const getErrorMessage = (response) => {
    if (response.status === 0) {
        return '情報の取得に失敗しました。ネットワーク状況を確認のうえ、画面の再読み込みをお願いします。';
    }
    if (response.status === 401) {
        return 'セッションが切れたため、自動的にログアウトされました。 再度ログインをお願いします。';
    }
    if (response.status === 403) {
        return 'アクセスが拒否されました。';
    }
    if (response.status < 500) {
        // 新フォーマットにはtitleがあり、それを表示する。無い場合は元のコード通りに取得する。
        return response.data.title || getDeprecatedErrorMessage(response.data);
    }

    return 'システムエラーが発生しました。しばらく経ってから再度実行してください。';
};

const getDeprecatedErrorMessage = (data) => (data.errors ? Object.values(data.errors)[0][0] : data);
